import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { FirebaseContext } from '../../components/Firebase/context';
import { withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import AgentVendorList from '../../components/AgentVendorList';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  button: {
    marginLeft: theme.spacing(2),
    display: 'flex',
  },
}));

function Transition(props) {
  return <Slide {...props} direction="left" />;
}

const AgentPage = () => {
  const classes = useStyles();
  const [isAddAgent, setIsAddAgent] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [transition, setTransition] = useState();
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const firebase = useContext(FirebaseContext);

  const getAgents = async () => {
    setLoading(true);
    const querySnapshot = await firebase.agents();
    const agentsList = querySnapshot.docs.map((doc) => ({ ...doc.data(), uid: doc.id }));
    setAgents(agentsList);
    setLoading(false);
  }

  useEffect(
    () => {
      getAgents();
      return () => { setAgents([]); }
    }, []); // eslint-disable-line

  const handleShowSuccessMsg = (id) => {
    setTransition(() => Transition);
    setOpen(true);
    setUserId(id);
  };

  const handleHideSuccessMsg = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleOpen = () => setIsAddAgent(true);
  const handleClose = () => setIsAddAgent(false);


  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <AgentVendorList
              getList={getAgents}
              list={agents}
              isAdd={isAddAgent}
              handleShowSuccessMsg={handleShowSuccessMsg}
              handleOpen={handleOpen}
              handleClose={handleClose}
              loading={loading}
              type="AGENT"
            />
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        TransitionComponent={transition}
        open={open}
        autoHideDuration={3000}
        onClose={handleHideSuccessMsg}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleHideSuccessMsg}
          severity="success"
        >
          L'agent {userId} a bien été ajouté.
        </Alert>
      </Snackbar>
    </Container>
  );
};

const condition = (authUser) => authUser && (authUser.role === ROLES.ADMIN || authUser.role === ROLES.AGENT);

export default compose(withAuthorization(condition))(AgentPage);