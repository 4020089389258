export function isBefore(date1, date2) {
  const day1 = date1.slice(8, 10);
  const month1 = date1.slice(5,7);
  const year1 = date1.slice(0,4);

  const day2 = date2.slice(8, 10);
  const month2 = date2.slice(5,7);
  const year2 = date2.slice(0,4);

  if(year1 < year2) return true;
  if(year1 === year2) {
    if(month1 < month2){
      return true;
    } 
    if(month1 === month2) {
      if(day1 < day2) {
        return true;
      } 
      if(day1 === day2) {
        return false;
      }
      if(day1 > day2) {
        return false;
      }
    }
    if(month1 > month2) return false;
  }
  if(year1 > year2) return false;
  return false;
}
export function isBeforeOrSame(date1, date2) {
  const day1 = date1.slice(8, 10);
  const month1 = date1.slice(5,7);
  const year1 = date1.slice(0,4);

  const day2 = date2.slice(8, 10);
  const month2 = date2.slice(5,7);
  const year2 = date2.slice(0,4);

  if(year1 < year2) return true;
  if(year1 === year2) {
    if(month1 < month2){
      return true;
    } 
    if(month1 === month2) {
      if(day1 < day2) {
        return true;
      } 
      if(day1 === day2) {
        return true;
      }
      if(day1 > day2) {
        return false;
      }
    }
    if(month1 > month2) return false;
  }
  if(year1 > year2) return false;
  return false;
}

export function isAfterOrSame(date1, date2) {
  const day1 = date1.slice(8, 10);
  const month1 = date1.slice(5,7);
  const year1 = date1.slice(0,4);

  const day2 = date2.slice(8, 10);
  const month2 = date2.slice(5,7);
  const year2 = date2.slice(0,4);

  if(year1 > year2) return true;
  if(year1 === year2) {
    if(month1 > month2){
      return true;
    } 
    if(month1 === month2) {
      if(day1 > day2) {
        return true;
      } 
      if(day1 === day2) {
        return true;
      }
      if(day1 < day2) {
        return false;
      }
    }
    if(month1 < month2) return false;
  }
  if(year1 < year2) return false;
  return false;
}

export function isAfter(date1, date2) {
  const day1 = date1.slice(8, 10);
  const month1 = date1.slice(5,7);
  const year1 = date1.slice(0,4);

  const day2 = date2.slice(8, 10);
  const month2 = date2.slice(5,7);
  const year2 = date2.slice(0,4);

  if(year1 > year2) return true;
  if(year1 === year2) {
    if(month1 > month2){
      return true;
    } 
    if(month1 === month2) {
      if(day1 > day2) {
        return true;
      } 
      if(day1 === day2) {
        return false;
      }
      if(day1 < day2) {
        return false;
      }
    }
    if(month1 < month2) return false;
  }
  if(year1 < year2) return false;
  return false;
}