/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  Fab,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import assets from '../../assets';
import { GammeFilters } from '../../components/CatalogFilters';
import { SearchInput } from '../../shared/Layout/SearchInput';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '30px 20px 0px 30px',
  },
  table: {
    minWidth: 700,
  },
  cellhead: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '23px',

    '& th:nth-child(1)': {
      width: '200px',
    },
    '& th:nth-child(2)': {
      width: '200px',
    },
    '& th:nth-child(3)': {
      width: '200px',
    },
  },
  imageVersion: {
    width: '232px',
    height: '142px',
  },
  Fab: {
    width: '35px',
    height: '30px',
    marginLeft: '15px',
    marginTop: '5px',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
    marginBottom: '20px',
    textTransform: 'none',
  },
  modal: {
    maxWidth: '536px',
    margin: '0 auto',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '36px',
    textAlign: 'center',
    textTransform: 'unset',
  },
  modalContent: {
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '23px',
    padding: '0 20px',
  },
  modalAction: {
    padding: '8px 40px',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  modalCancelBtn: {
    backgroundColor: '#313639',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#313639',
      color: 'white',
    },
  },
  modalSubmitBtn: {
    backgroundColor: '#D5001C',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#D5001C',
      color: 'white',
    },
  },
  iconClose: {
    position: 'absolute',
    right: '20px',
    top: '17px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
  msgText: {
    paddingTop: '20px',
  },
}));

const VersionListPage = ({ firebase, history }) => {
  const [versions, setVersions] = useState([]);
  const classes = useStyles();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedVersion, setSelectedVersion] = React.useState(null);
  const [gammesID, setGammesId] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState({
    isOpen: false,
    msg: '',
  });

  const [state, setState] = useState({
    alert: {
      msg: '',
      type: 'error',
      show: false,
    },
  });

  const DELETE_ERROR_MSG = 'Désolé, cette version n’a pas pu être supprimée';

  const getVersions = React.useCallback(async () => {
    setLoading(true);
    const gammesSnapshot = await firebase.db.collection('porsche-gammes').orderBy('order');
    const modelsSnapshot = await firebase.db.collection('porsche-models').orderBy('order');
    const versionsSnapshot = await firebase.db.collection('porsche-versions').orderBy('order');
    const gammes = await gammesSnapshot.get();
    const versionsList = [];

    const promises = gammes.docs.map(async (gamme) => {
      const gammeId = gamme.id;
      const gammeName = gamme.data().name;
      const models = await modelsSnapshot.where('gamme_id', '==', gammeId).get();
      const promiseModel = await models.docs.map(async (doc) => {
        const modelId = doc.id;
        const dataModels = doc.data();
        const version = await versionsSnapshot.where('model_id', '==', modelId).get();

        version.docs.map(async (document) => {
          const data = await document.data();
          versionsList.push({
            gammeId,
            name: data.name,
            key: document.id,
            modelName: dataModels.name,
            modelId,
            gammeName,
            url: data.url,
            codeModel: data.codeModel,
            primaryInterest: data.primary_interest,
          });
        });
      });
      await Promise.all(promiseModel);
    });
    await Promise.all(promises);

    setVersions(versionsList);
    setLoading(false);
    showAlert(versionsList);
  }, [firebase.db]);

  useEffect(() => {
    getVersions();
  }, [getVersions]);

  function handleEdit(version) {
    history.push({
      pathname: '/update-version',
      state: {
        key: version?.key,
        versionName: version?.name,
        versionURL: version?.url,
        codeModel: version?.codeModel,
        modelId: version?.modelId,
        modelName: version?.modelName,
        primaryInterest: version?.primaryInterest,
      },
    });
  }

  const showAlert = (versionsUrl) => {
    const versionsUrlOk = versionsUrl.filter((model) => !model.url);
    if (versionsUrlOk.length > 0) {
      const msg = `Erreur de chargement de ${versionsUrlOk.length} images. Veuillez rafraichir la page`;
      setState({ alert: { show: true, msg, type: 'error' } });
    }
  };

  function handleAdd() {
    history.push('add-version');
  }

  const handleOpenModal = (version) => {
    setSelectedVersion(version);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  function handleCloseAlert() {
    setAlert({
      isOpen: false,
      msg: '',
    });
  }

  async function handleDelete() {
    try {
      const versionRef = firebase.db.collection('porsche-versions');
      versionRef
        .doc(selectedVersion?.key)
        .delete()
        .then(async () => {
          if (selectedVersion.url) {
            const storageRef = firebase.storage.ref(`versions/${selectedVersion?.key}`);
            storageRef.delete();
          }
          try {
            getVersions();
            handleCloseModal();
          } catch {
            setAlert({
              isOpen: true,
              msg: DELETE_ERROR_MSG,
            });
          }
        });
    } catch (err) {
      handleCloseModal();
      setAlert({
        isOpen: true,
        msg: DELETE_ERROR_MSG,
      });
    }
  }

  function filterByGamme(version) {
    return gammesID.length > 0 ? gammesID.includes(version.gammeId) : true;
  }

  function filterByVersionName(version) {
    return searchedWord.length > 0 ? version.name.toLowerCase().indexOf(searchedWord) > -1 : true;
  }

  const versionSearchFilter = () => versions.filter(filterByVersionName).filter(filterByGamme);

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        onClick={handleAdd}
      >
        Ajouter une version
      </Button>
      {state.alert.show && (
        <Alert
          severity="error"
          style={{ width: '50%', position: 'absolute', top: 20, right: 20 }}
          onClose={() => {
            setState({ alert: { show: false, msg: '' } });
          }}
        >
          {state.alert.msg}
        </Alert>
      )}
      {alert.isOpen && (
        <Alert
          severity="error"
          style={{ width: '50%', position: 'absolute', top: 20, right: 20 }}
          onClose={handleCloseAlert}
        >
          {alert.msg}
        </Alert>
      )}
      <SearchInput
        setSearchFunction={setSearchedWord}
        label="Rechercher une version"
        maxLength={50}
      />
      <GammeFilters setGammesId={setGammesId} gammesID={gammesID} />
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress disableShrink color="secondary" />
        </div>
      ) : versions && versionSearchFilter(versions).length > 0 ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.cellhead}>
                <StyledTableCell className={classes.cellhead} align="center">
                  Visuel
                </StyledTableCell>
                <StyledTableCell className={classes.cellhead} align="center">
                  Nom
                </StyledTableCell>
                <StyledTableCell className={classes.cellhead} align="center">
                  Modèle
                </StyledTableCell>
                <StyledTableCell className={classes.cellhead} align="center">
                  Gamme
                </StyledTableCell>
                <StyledTableCell className={classes.cellhead} align="center">
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {versionSearchFilter(versions).map((version, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell align="center">
                    <img
                      className={classes.imageVersion}
                      src={version.url ? version.url : assets.IMG_PLACEHOLDER}
                      alt="carPicture"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center">{version.name}</StyledTableCell>
                  <StyledTableCell align="center">{version.modelName}</StyledTableCell>
                  <StyledTableCell align="center">{version.gammeName}</StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      <Fab className={classes.Fab} color="primary" aria-label="view">
                        <DeleteIcon onClick={() => handleOpenModal(version)} />
                      </Fab>
                      <Fab
                        className={classes.Fab}
                        color="default"
                        onClick={() => {
                          handleEdit(version);
                        }}
                      >
                        <EditIcon />
                      </Fab>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog
            open={isDeleteModalOpen && !!selectedVersion}
            onClose={handleCloseModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.modal}
          >
            <IconButton className={classes.iconClose} aria-label="close" onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
            <p className={classes.modalTitle} id="alert-dialog-title">
              Confirmation de suppression
            </p>
            <DialogContent>
              <p className={classes.modalContent} id="alert-dialog-description">
                Souhaitez-vous vraiment supprimer la version {selectedVersion?.name}.
              </p>
            </DialogContent>
            <DialogActions className={classes.modalAction}>
              <Button onClick={handleCloseModal} className={classes.modalCancelBtn}>
                Annuler
              </Button>
              <Button onClick={handleDelete} className={classes.modalSubmitBtn}>
                &gt;&nbsp; Supprimer
              </Button>
            </DialogActions>
          </Dialog>
        </TableContainer>
      ) : (
        <span className={classes.msgText}>Aucun résultat ne correspond à votre recherche</span>
      )}
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(VersionListPage);
