export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))  } ${  sizes[i]}`;
}


export const formatDate = (timestamp) => {
  const date = timestamp.toDate();
  const day = date.getDate().toString().padStart(2,'0');
  const month = (date.getMonth() + 1).toString().padStart(2,'0');
  const year = date.getFullYear().toString().padStart(2,'0');
  
  return `${day}.${month}.${year}`;
} 

export const formatHour = (timestamp) => {
  const date =timestamp.toDate();
  const h = date.getHours();
  const m = date.getMinutes();
  return `${h}H${m}Min`;
}
