/* eslint-disable no-console */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'recompose';
import assets from '../../assets';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import * as ROLES from '../../constants/roles';
import timestampToDate from '../../utils/timestampToDate';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);



const StyledTableSortLabel = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: "black",
      color: 'white',
      "&:hover": {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  })
)(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  title: {
    margin: theme.spacing(0, 0, 2, 1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  progress: {
    marginLeft: theme.spacing(65),
    marginTop: theme.spacing(30),
  },
  cellhead: {
    fontSize: '15px',
    backgroundColor: "black",
  },
  ArrowDown: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tablecell: {
    fontSize: '20px',
  },
}));

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'bpNumber', label: 'Identifiant (BP)' },
    { id: 'civility', label: 'Civilité' },
    { id: 'firstName', label: 'Prénom' },
    { id: 'lastName', label: 'Nom' },
    { id: 'email', label: 'E-mail' },
    { id: 'phoneNumber', label: 'Téléphone' },
    { id: 'streetNumber', label: 'Numéro de rue' },
    { id: 'street', label: 'Adresse' },
    { id: 'postalCode', label: 'Code postal' },
    { id: 'city', label: 'Ville' },
    { id: 'country', label: 'Pays' },
    { id: 'status', label: 'Statut' },
    { id: 'nbEscort', label: 'Nombre d\'accompagnant(s)' },
    { id: 'group', label: 'Groupe' },
    { id: 'chassisTested', label: 'Châssis essayé' },
    { id: 'startTime', label: 'Heure début' },
    { id: 'endTime', label: 'Heure fin' },
    { id: 'vinCode', label: 'Châssis ajouté' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            style={{ align: "Left" }}
            className={classes.cellhead}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const EventDetailPage = (props) => {
  const authUser = useContext(AuthUserContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('bpNumber');
  const [state, setState] = useState({
    errorFormat: '',
    infoMessage: '',
    currentEventId: null,
    loading: false,
    event: null,
    alert: {
      msg: '',
      type: 'error',
      show: false,
    },
    loader: false,
    porscheCenters: [],
    currentPorscheCenter: '',
    ...props.location.state,
  });

  const PersonsRef = props.firebase.db.collection('persons');
  const GuestsRef = props.firebase.db.collection('guests');
  const classes = useStyles();
  const normalizeDate = (date) => (String(date).length === 1 ? `0${date}` : date);
  const normalizeFileName = ({ fileName, eventName }) => {
    const date = new Date()
    return `${fileName}_${normalizeDate(date.getDate())
    }-${normalizeDate(date.getMonth() + 1)
    }-${date.getFullYear()}_${eventName}`
  }
  const exportLeadClientOrTotemFromEvent = (eventId, isLead, docName) => {
    setState({ ...state, loading: true });
    props.firebase.auth.currentUser.getIdToken(true)
      .then((token) => {
        axios({
          url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/exportLeadClientOrTotemFromEvent`,
          method: 'GET',
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${token}` },
          params: { id: eventId, isLead, docName },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data],
            { type: { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' } }));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${docName}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setState({ ...state, loading: false });
        }).catch((error) => {
          const status = (error.response && (error.response.status || error));

          const msg = status === 404
            ? 'Actuellement, aucun prospect n\'est rattaché à cet évènement'
            : 'Une erreur s’est produite lors de l’export. Veuillez réessayer ou contacter un responsable technique';
          setState({ ...state, alert: { show: true, msg, type: 'error' }, loading: false });
        });
      })
      .catch((error) => {
        console.log('Error getting refreshed Firebase Token', error);
      });
  };


  useEffect(() => {
    let cancel = false;
    async function fetchData() {
      setState({ ...state, loading: true });
      props.firebase.event(props.match.params.id).onSnapshot(async (snapshot) => {
        const data = snapshot.data();
        const guest = await GuestsRef.where('event_id', '==', props.match.params.id).get();
        const promises = guest ? guest.docs.map(async (doc) => {
          const person = await PersonsRef.doc(doc.data().person_id).get();
          return { ...doc.data(), ...person.data() };
        }) : null;

        const guests = await Promise.all(promises);
        const porscheCenters = authUser.role === ROLES.ADMIN ? (await props.firebase.db
          .collection('porsche-centers')
          .get()).docs.map((i) => i.data().porscheCenterName) : authUser.porscheCenters;
        Object.assign(data, {
          ...event,
          startDate: moment.tz(data.startDate.toDate(), "Europe/Paris").format(),
          endDate: moment.tz(data.endDate.toDate(), "Europe/Paris").format(),
          creationDate: moment.tz(data.creationDate, "Europe/Paris").format(),
        });

        if (!cancel) {
          setState({
            ...state,
            currentEventId: props.match.params.id,
            event: { ...data, guests },
            loading: false,
            porscheCenters,
            currentPorscheCenter: porscheCenters[0],
          });
        }
      });
    }
    setState({ ...state, loading: true });
    fetchData();
    return () => { cancel = true; }
  }, []); //eslint-disable-line

  const handleChangePorscheCenter = (e) => {
    setState({ ...state, currentPorscheCenter: e.target.value });
  }

  const generateTemplateGuest = async () => {
    setState({ ...state, loading: true });
    const link = document.createElement('a');
    link.href = assets.templateGuest;
    link.download = 'templateGuest.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setState({ ...state, loading: false, errorFormat: '' });
  }



  const downloadPresentParticipants = async (id) => {
    try {
      setState({ ...state, loading: true });
      const token = await props.firebase.auth.currentUser.getIdToken(true);
      axios({
        url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/exportPresentParticipantsByEventId/${id}`,
        method: 'GET',
        responseType: 'blob',
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
              },
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.download = `${normalizeFileName({
            fileName: 'Participants',
            eventName: event.name,
          })}.xlsx`.toUpperCase();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setState({ ...state, loading: false });
        })
        .catch((error) => {
          const msg =
            error.response.status === 400
              ? 'Actuellement, aucun participant n’est noté comme présent'
              : 'Une erreur s’est produite lors de l’export. Veuillez réessayer ou contacter un responsable technique';
          setState({ ...state, alert: { show: true, msg, type: 'error' }, loading: false });
        });
    } catch (error) {
      console.log('error:::', error);
    }
  };



  const downloadPresentGuest = async (id) => {
    setState({ ...state, loading: true });
    props.firebase.auth.currentUser.getIdToken(true)
      .then((token) => {
        axios({
          url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/exportPresentLeadsByEventId/${id}`,
          method: 'GET',
          responseType: 'blob',
          headers: { 'Authorization': `Bearer ${token}` },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data],
            { type: { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' } }));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${normalizeFileName({ fileName: 'P.COM', eventName: event.name })}.xlsx`.toUpperCase();
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setState({ ...state, loading: false });
        }).catch((error) => {
          // eslint-disable-next-line no-unused-vars
          const msg = error.response.status === 400 ?
            'Actuellement, aucun participant n’est noté comme présent' :
            'Une erreur s’est produite lors de l’export. Veuillez réessayer ou contacter un responsable technique';
          setState({ ...state, alert: { show: true, msg, type: 'error' }, loading: false });
        });
      })
      .catch((error) => {
        console.log('Error getting refreshed Firebase Token', error);
      });
  };

  const importGuests = (e) => {
    setState({ ...state, loading: true, errorFormat: '' });
    const { files } = e.target;

    if (files && files[0]) {
      if (files[0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setState({ ...state, loading: false, errorFormat: 'Votre fichier n\'est pas au format .xlsx' });
      } else {
        const formData = new FormData();
        const file = new Blob([files[0]]);
        formData.append('xlsxImport', file, file.filename);

        props.firebase.auth.currentUser.getIdToken(true)
          .then((token) => {
            axios.post(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/processGuestImport`,
              formData,
              {
                params: { id: props.match.params.id, porscheCenter: state.currentPorscheCenter },
                headers: { 'Authorization': `Bearer ${token}` },
              }).then((response) => {
              console.log('response', response);
              // avoid constant re-render of the list once the import is done
              window.location.reload();
            }).catch((error) => {
              console.log('Error during import of guests', error);
            });
          })
          .catch((error) => {
            console.log('Error getting refreshed Firebase Token', error);
          });
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const exportConsentsDisclaimers = async (eventId) => {
    setState({ ...state, loading: true });
    const date = moment(new Date()).format("DD-MM-YYYY");
    props.firebase.auth.currentUser.getIdToken(true)
      .then((token) => {
        axios({
          url: `${process.env.REACT_APP_IMPORT_EXPORT_URL}/exportConsentsDisclaimers`,
          method: 'POST',
          responseType: 'blob',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          data: {
            'eventId': eventId,
          },
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = `Export décharges et consentements ${date}.zip`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setState({ ...state, loading: false });
        }).catch((error) => {
          const status = (error.response && (error.response.status || error));
          const msg = status === 404
            ? "Export indisponible. Aucun consentement et/ou décharges n'ont été remplis sur cet évènement."
            : "Une erreur s'est produite lors de l'export. Veuillez réessayer ou contacter un responsable technique";
          setState({ ...state, alert: { show: true, msg, type: 'error' }, loading: false });
        });
      })
      .catch((error) => {
        console.log('Error getting refreshed Firebase Token', error);
      });
  };

  const { event, loading, alert } = state;
  return loading || !event ? (<div>
    <div
      style={{ margin: '20px 0' }}>
      <LinearProgress />
    </div>
    <div>
      <LinearProgress color="secondary" onClose={() => { setState({ ...state, loading: false }) }} />
    </div>
  </div>) :
    <div>
      <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10}}>
        <div>
          <h2>Évènement: {event.name}</h2>
          <span>
            <strong>Nom:</strong> {event.name}
            <br />
          </span>
          <span>
            <strong>Date de création:</strong> {timestampToDate(event.creationDate)}
            <br />
          </span>
          <span>
            <strong>Date de début:</strong> {timestampToDate(event.startDate)}
            <br />
          </span>
          <span>
            <strong>Date de fin:</strong> {timestampToDate(event.endDate)}
            <br />
          </span>
          <span>
            <strong>Code Campagne:</strong> {event.campagneCode}
            <br />
          </span>
          <span>
            <strong>Centres Porsche liés:</strong>
            {event.porscheCenters &&
          event.porscheCenters.map((center) => (
            <li key={center}>{center}</li>
          ))}
            <br />
          </span>
        </div>

        <div className={classes.buttons} style={{marginTop: '15px'}}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => generateTemplateGuest()}
          >
          Télécharger le modèle de fichier
          </Button>
          <label
            htmlFor="xlsxImport"
            style={{
              cursor: 'pointer',
              backgroundColor: '#d5001c',
              color: '#fff',
              boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              fontSize: '0.95rem',
              minWidth: '64px',
              padding: '7px 16px',
              boxSizing: 'border-box',
              fontFamily: 'PorscheNextTT, sans-serif',
              fontWeight: 700,
              lineHeight: 1.75,
              borderRadius: '4px',
            }}
          >
          IMPORTER LES INVITÉS
          </label>
          <input
            type="file"
            id="xlsxImport"
            name="xlsxImport"
            accept=".xlsx, .xls"
            style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
            onChange={importGuests}
          />
        </div>
      </div>

      <div className={classes.buttons} style={{marginTop: 15, paddingBottom: 15 }}>
       
        {authUser.role === ROLES.ADMIN ?
          <Button
            variant="contained"
            color="secondary"
            style={{backgroundColor: '#959899'}}
            onClick={() => downloadPresentGuest(props.match.params.id)}
          >
            EXPORT P.COM
          </Button>
          : null
        }
        <Button
          href="#"
          color="secondary"
          style={{backgroundColor: '#959899'}}
          variant="contained"
          adding="6px 16px"
          onClick={() => exportLeadClientOrTotemFromEvent(
            props.match.params.id,
            false,
            normalizeFileName({ fileName: 'LeadTotem', eventName: event.name }))
          }
        >
          Exporter les leads du totem
        </Button>
        <Button
          style={{backgroundColor: '#959899'}}
          color="secondary"
          variant="contained"
          onClick={() => exportConsentsDisclaimers(props.match.params.id)
          }
        >
          Export Décharges et consentements
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{backgroundColor: '#959899'}}
          onClick={() => downloadPresentParticipants(props.match.params.id)}
        >
          Export participants
        </Button>


      </div>
      <select onChange={handleChangePorscheCenter}> {/* eslint-disable-line */}
        {state.porscheCenters.map((porscheC) =>
          <option key={porscheC.toString()} value={porscheC}>{porscheC}</option>)}
      </select>
      <br />
      <br />
      {state.infoMessage && (
        <Typography style={{ padding: '10px' }} color="green">
          {state.infoMessage}
        </Typography>
      )}
      {state.errorFormat && (
        <Typography style={{ padding: '10px' }} color="error">
          {state.errorFormat}
        </Typography>
      )}

      {
        alert.show && (<Alert
          severity="error"
          style={{ width: '50%', position: 'absolute', top: 80, right: 20 }}
          onClose={() => { setState({ ...state, alert: { show: false, msg: '' } }) }}>
          {alert.msg}
        </Alert>
        )
      }
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h6" color="primary" className={classes.title}>
            Liste des invités: {event.guests.length}
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(event.guests, getComparator(order, orderBy)).map((guest) => (
                  <StyledTableRow key={guest.person_id}>
                    <StyledTableCell align="left">{guest.bpNumber}</StyledTableCell>
                    <StyledTableCell align="left">{guest.civility}</StyledTableCell>
                    <StyledTableCell align="left">{guest.firstName}</StyledTableCell>
                    <StyledTableCell align="left">{guest.lastName}</StyledTableCell>
                    <StyledTableCell align="left">{guest.email}</StyledTableCell>
                    <StyledTableCell align="left">{guest.phoneNumber}</StyledTableCell>
                    <StyledTableCell align="left">{guest.streetNumber}</StyledTableCell>
                    <StyledTableCell align="left">{guest.street}</StyledTableCell>
                    <StyledTableCell align="left">{guest.postalCode}</StyledTableCell>
                    <StyledTableCell align="left">{guest.city}</StyledTableCell>
                    <StyledTableCell align="left">{guest.country}</StyledTableCell>
                    <StyledTableCell align="left">{guest.status}</StyledTableCell>
                    <StyledTableCell align="left">{guest.nbEscort}</StyledTableCell>
                    <StyledTableCell align="left">{guest.group}</StyledTableCell>
                    <StyledTableCell align="left">{guest.chassisTested}</StyledTableCell>
                    <StyledTableCell align="left">{typeof guest.startTime === 'string' ? guest.startTime : ''}</StyledTableCell>
                    <StyledTableCell align="left">{typeof guest.endTime === 'string' ? guest.endTime : ''}</StyledTableCell>
                    <StyledTableCell align="left">{guest.vinCode}</StyledTableCell>
                  </StyledTableRow>))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <br />
      <br />
      <br />
    </div>
};

const condition = (authUser) => !!authUser;

export default compose(withFirebase, withAuthorization(condition))(EventDetailPage);