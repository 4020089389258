import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  Fab,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import assets from '../../assets';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    '&:first-child': {
      cursor: 'pointer',
    },
  },
}))(TableCell);


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '30px 20px 0px 30px',
  },
  table: {
    minWidth: 700,
  },
  cellhead: {
    fontSize: '16px',
    'fontWeight': 'bold',
    'letterSpacing': 0,
    'lineHeight': '23px',
    '& th:nth-child(1)': {
      width: '116px',
    },
    '& th:nth-child(3)': {
      width: '430px',
      textAlign: 'left',
    },
  },
  Fab: {
    width: '35px',
    height: '30px',
    marginLeft: '15px',
    marginTop: '5px',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
    marginBottom: '20px',
    textTransform: 'none',
  },
  rowImg: {
    height: '142px',
    width: '189px',
  },
  modal: {
    maxWidth: '536px',
    margin: '0 auto',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '36px',
    textAlign: 'center',
    textTransform: 'unset',
  },
  modalContent: {
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '23px',
    padding: '0 20px',
  },
  moalAction: {
    padding: '8px 40px',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  modalCancelBtn: {
    backgroundColor: '#313639',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#313639',
      color: 'white',
    },
  },
  modalSubmitBtn: {
    backgroundColor: '#D5001C',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#D5001C',
      color: 'white',
    },
  },
  iconClose: {
    position: 'absolute',
    right: '20px',
    top: '17px',
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const GammeListPage = ({ firebase, history }) => {
  const [gammes, setGammes] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedGamme, setSelectedGamme] = React.useState(null);
  const [alert, setAlert] = React.useState({
    isOpen: false,
    msg: '',
  });

  const DELETE_ERROR_MSG = 'Désolé, cette gamme n’a pas pu être supprimée';
  const classes = useStyles();

  const getGammes = React.useCallback(async () => {
    const gammesSnapshot = await firebase.db.collection('porsche-gammes')
      .orderBy('order')
      .get();
    if (!gammesSnapshot.empty) {
      const gammesList = [];
      gammesSnapshot.forEach((doc) => gammesList.push({
        ...doc.data(),
        key: doc.id,
      }))
      setGammes(gammesList)
      return;
    }
    setGammes([]);

  }, [firebase.db])

  useEffect(() => {
    getGammes();
  }, [getGammes]);

  function handleAdd() {
    history.push('add-gamme')
  }

  async function deleteVersions({ modelId }) {
    firebase.db.collection("porsche-versions")
      .where('model_id', '==', modelId).get().then((resp) => {
        const batchDeleteVersions = firebase.db.batch()
        resp.docs.forEach((doc) => {
          const docRef = firebase.db.collection('porsche-versions').doc(doc.id)
          batchDeleteVersions.delete(docRef)
          if(doc.data().url){
            const storageRef = firebase.storage.ref(`versions/${doc.id}`);
            storageRef.delete()
          }
        })
        batchDeleteVersions.commit()
          .catch(() => setAlert({
            isOpen: true,
            msg: DELETE_ERROR_MSG,
          }))
      })
  }

  async function deleteModels({ gammeId, callBack }) {
    const models = [];
    firebase.db.collection("porsche-models")
      .where('gamme_id', '==', gammeId).get().then((resp) => {
        const batchDeleteModels = firebase.db.batch()
        resp.docs.forEach((doc) => {
          const docRef = firebase.db.collection('porsche-models').doc(doc.id)
          models.push(doc.id)
          batchDeleteModels.delete(docRef)
        })
        batchDeleteModels.commit()
          .then(async () => {
            const promises = models.map((id) => deleteVersions({ modelId: id }))
            await Promise.all(promises)
            callBack()
          })
          .catch(() => setAlert({
            isOpen: true,
            msg: DELETE_ERROR_MSG,
          }))
      })
  }

  async function handleDelete() {
    try {
      const gammeRef = firebase.db.collection("porsche-gammes");
      gammeRef.doc(selectedGamme?.key).delete().then(async () => {
        if (selectedGamme.url!==undefined) {
          const storageRef = firebase.storage.ref(`gammes/${selectedGamme?.key}`);
          storageRef.delete()}
        try{
          deleteModels({
            gammeId: selectedGamme?.key,
            callBack: () => {
              getGammes();
              handleCloseModal();
            },
          });
        }
        catch {setAlert({
          isOpen: true,
          msg: DELETE_ERROR_MSG,
        })
        }
      });
    } catch (err) {
      handleCloseModal();
      setAlert({
        isOpen: true,
        msg: DELETE_ERROR_MSG,
      })
    }
  }

  function handleEdit(gamme) {
    history.push({
      pathname: '/update-gamme/',
      state: {
        key: gamme?.key,
        gammeName: gamme?.name,
        gammeURL: gamme?.url,
      },
    })
  }

  const handleOpenModal = (gamme) => {
    setSelectedGamme(gamme)
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  function handleCloseAlert() {
    setAlert({
      isOpen: false,
      msg: '',
    })
  }

  async function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(gammes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setGammes(items);
    firebase.db.collection("porsche-gammes").get().then((resp) => {
      const batch = firebase.db.batch()
      resp.docs.forEach((doc) => {
        const order = items.findIndex((item) => item.key === doc.id)
        const docRef = firebase.db.collection('porsche-gammes').doc(doc.id)
        batch.update(docRef, { order })
      })
      batch.commit()
        .then(() => {
          getGammes()
        })
        .catch(() => setAlert({
          isOpen: true,
          msg: "Le changement de position n'a pas pu être enregistré",
        }))
    })
  }

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        onClick={handleAdd}
      >
        Ajouter une gamme
      </Button>
      {alert.isOpen && (<Alert
        severity="error"
        style={{ width: '50%', position: 'absolute', top: 20, right: 20 }}
        onClose={handleCloseAlert}>
        {alert.msg}
      </Alert>
      )}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow className={classes.cellhead}>
              <StyledTableCell className={classes.cellhead} align="center">
                Position
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Visuel
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Nom
              </StyledTableCell>
              <StyledTableCell className={classes.cellhead} align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="gammes">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {gammes && gammes.map((gamme, index) => (
                    <Draggable key={gamme.key} draggableId={gamme.key} index={index}>
                      {(provid) => (
                        <StyledTableRow ref={provid.innerRef} {...provid.draggableProps} {...provid.dragHandleProps}>
                          <StyledTableCell align="center">
                            <img alt="icon-drag-drop" src={assets.dragDropIcon} />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <img className={classes.rowImg} src={gamme.url ? gamme.url : assets.IMG_PLACEHOLDER} alt="carPicture" />
                          </StyledTableCell>
                          <StyledTableCell align="left">{gamme.name}</StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <Fab className={classes.Fab} color="primary" aria-label="view">
                                <DeleteIcon onClick={() => handleOpenModal(gamme)} />
                              </Fab>
                              <Fab className={classes.Fab} color="default" onClick={() => handleEdit(gamme)}>
                                <EditIcon />
                              </Fab>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
        <Dialog
          open={isDeleteModalOpen && !!selectedGamme}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modal}
        >
          <IconButton className={classes.iconClose} aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <p className={classes.modalTitle} id="alert-dialog-title">Confirmation de suppression</p>
          <DialogContent>
            <p className={classes.modalContent} id="alert-dialog-description">
              Souhaitez-vous vraiment supprimer la gamme {selectedGamme?.name}. Attention, la suppression de cette gamme entrainera la suppression de tous les modèles associés
            </p>
          </DialogContent>
          <DialogActions className={classes.moalAction}>
            <Button onClick={handleCloseModal} className={classes.modalCancelBtn}>
              Annuler
            </Button>
            <Button onClick={handleDelete} className={classes.modalSubmitBtn}>
              &gt;&nbsp; Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(GammeListPage);
