import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  TextField,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  Button,
} from '@material-ui/core';
import { Form } from 'formik';
import assets from '../../assets';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  form: {
    width: '100%',
    maxWidth: '1100px',
  },
  inputFileContainer: {
    marginTop: '25px',
    marginRight: '20px',
  },
  input: {
    width: '409px',
  },
  imgContainer: {
    height: '350px',
    maxWidth: '600px',
    width: '600px',
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    '& > img': {
      height: '400px',
      width: '100%',
    },
  },

  fileControl: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-start',
    maxWidth: '388px',
    marginTop: '10px',
  },
  button: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'none',
    marginRight: '30px',
  },
  row: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
  },
  inputTextContainer: {
    paddingTop: '10px',
    paddingLeft: '66px',
  },
  formControl: {
    minWidth: 409,
    marginTop: 10,
    '& Label': {
      background: '#fff',
      padding: '0 5px',
    },
  },
  selectStyle: {
    background: '#fff',
  },
}));

const FILE_PROPS = {
  width: 535,
  height: 330,

  errors: {
    format:
      'Format erroné, veuillez respecter la taille d’image suivante : 535px width 330px height',
    required: 'Veuillez charger une image',
  },
};

const VersionForm = ({ formik, isLoading, firebase, handleSubmit, mutation, initialValues }) => {

  const classes = useStyles();
  const [modelList, setModelList] = useState([]);
  const [fileError, setFileError] = useState({
    isValid: true,
    msg: '',
  });
  const hiddenFileInput = useRef(null);
  useEffect(() => {
    if (mutation === 'EDIT') {
      setFileError({
        isValid: true,
        msg: '',
      });
    }
  }, [mutation]);

  useEffect(() => {
    const asyncFetch = async () => {
      const getModels = await firebase.db.collection('porsche-models').orderBy('order').get()
      const models = [];

      getModels.docs.map(async (doc) => {
        const modelData = doc.data();
        models.push({ id: doc.id, model: modelData.name, orderModel: modelData.order });
      });
      setModelList(models);
    };

    asyncFetch();
  }, [firebase.db]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const { type } = file;
    if (!type.startsWith('image/')) {
      setFileError({
        isValid: false,
        msg: FILE_PROPS.errors.required,
      });
      return;
    }
    try {
      const fileUploadedUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = fileUploadedUrl;
      img.onload = () => {
        if (img.height !== FILE_PROPS.height || img.width !== FILE_PROPS.width) {
          setFileError({
            isValid: false,
            msg: FILE_PROPS.errors.format,
          });
          return;
        }
        formik.setFieldValue('fileUrl', fileUploadedUrl);
        setFileError({
          isValid: true,
          msg: '',
        });
      };
    } catch (e) {
      console.error('Error uploading', e);
    }
  };

  const resetFileInput = () => {
    formik.setFieldValue('fileUrl', '');
  };
  const isDisabled =
    isLoading ||
    !formik.values.versionName ||
    !formik.values.modelId ||
    !formik.values.codeModel ||
    !formik.values.primaryInterest ||
    !fileError.isValid

  const handleAdd = async () => {
    try {
      const docRef = await firebase.db
        .collection('porsche-versions')
        .get();
      if (initialValues?.versionName !== formik.values.versionName &&
        docRef.docs.length > 0 &&
        (docRef.docs.findIndex((version) => version.data().name.toLowerCase() === formik.values.versionName.toLowerCase()) !== -1)
      ) {
        formik.setFieldError('versionName', 'Cette version existe déjà');
      } else {
        handleSubmit(formik.values);
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }
  };

  return (
    <Form className={classes.form}>
      <h1>Catalogue</h1>
      <div className={classes.container}>
        <div>
          <div className={classes.titleContainer}>
            <h2>{mutation === 'ADD' ? 'Création de la version' : formik.values.versionName}</h2>
          </div>
          <div className={classes.row}>
            <div className={classes.inputFileContainer}>
              <div className={classes.imgContainer}>
                <img src={formik.values.fileUrl ? formik.values.fileUrl : assets.IMG_PLACEHOLDER} className={classes.img} alt="" />
              </div>

              <div>
                {!fileError.isValid && fileError.msg && (
                  <FormHelperText error>{fileError.msg}</FormHelperText>
                )}
              </div>

              <div className={classes.fileControl}>
                <div>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleClick}
                  >
                    Ajouter un visuel
                  </Button>

                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    key={formik.values.fileUrl}
                  />
                </div>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    backgroundColor: '#959899',

                    display: formik.values.fileUrl === '' ? 'none' : 'block',
                  }}
                  onClick={resetFileInput}
                >
                  Supprimer le visuel
                </Button>
              </div>
            </div>

            <div className={classes.inputTextContainer}>
              <TextField
                name="versionName"
                error={formik.errors.versionName && formik.touched.versionName}
                variant="outlined"
                type="text"
                margin="normal"
                fullWidth
                helperText={
                  formik.errors.versionName && formik.touched.versionName
                    ? formik.errors.versionName
                    : ''
                }
                label="Version"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.versionName}
                className={classes.input}
              />

              <FormControl
                variant="outlined"
                id="demo-simple-select-outlined-label"
                className={classes.formControl}
              >
                <InputLabel id="modelId" error={formik.errors.modelId && formik.touched.modelId}>
                  Modèle
                </InputLabel>
                <Select
                  labelId="modelId"
                  id="modelId"
                  variant="outlined"
                  error={formik.errors.modelId && formik.touched.modelId}
                  name="modelId"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="modelId"
                  value={formik.values.modelId}
                >
                  {modelList?.map((mod) => (
                    <MenuItem key={mod.id} value={mod.id}>
                      {mod.model}
                    </MenuItem>
                  ))}
                </Select>

                {formik.errors.modelId && formik.touched.modelId ? (
                  <FormHelperText error={formik.errors.modelId && formik.touched.modelId}>
                    {formik.errors.modelId}
                  </FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>

              <TextField
                name="codeModel"
                error={formik.errors.codeModel && formik.touched.codeModel}
                variant="outlined"
                type="text"
                margin="normal"
                fullWidth
                helperText={
                  formik.errors.codeModel && formik.touched.codeModel ? formik.errors.codeModel : ''
                }
                label="Code modèle"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.codeModel}
                className={classes.input}
              />

              <TextField
                name="primaryInterest"
                error={formik.errors.primaryInterest && formik.touched.primaryInterest}
                variant="outlined"
                type="text"
                margin="normal"
                fullWidth
                helperText={
                  formik.errors.primaryInterest && formik.touched.primaryInterest
                    ? formik.errors.primaryInterest
                    : ''
                }
                label="Primary_Interest"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.primaryInterest}
                className={classes.input}
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        disabled={isDisabled}
        onClick={handleAdd}
      >
        Enregistrer
      </Button>
    </Form>
  );
};

export default VersionForm;
