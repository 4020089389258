import React, { useContext } from 'react';
import { AppBar, Button, Grid, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import assets from '../../../assets';
import { AuthUserContext } from '../../../components/Session';
import * as ROUTES from '../../../constants/routes';
import ToggleMenu from './ToggleMenu';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    height: 50,
    width: 110,
    margin: 5,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  backButton: {
    marginLeft: '230px',
    '& > *': {
      marginLeft: '0.5rem',
    },
  },
}));

const NavigationAuth = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const eventDetailPage = /event\/.*/;
  const isHandleGamme =
    location.pathname === ROUTES.ADDGAMME || location.pathname === ROUTES.UPDATEGAMME;
  const isHandleModel =
    location.pathname === ROUTES.ADD_MODEL || location.pathname === ROUTES.UPDATE_MODEL;
  const isHandleVersion =
    location.pathname === ROUTES.ADD_VERSION || location.pathname === ROUTES.UPDATE_VERSION;

  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.toolbarTitle} />
      <Grid container direction="row" justify="space-between" alignItems="flex-start">
        {eventDetailPage.test(location.pathname) ||
          location.pathname === ROUTES.ADD_EVENT ||
          isHandleGamme ||
          isHandleModel ||
          isHandleVersion ? (
            <div className={classes.backButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (isHandleGamme) {
                    history.push({
                      pathname: '/catalog',
                      state: {
                        isGamme: true,
                      },
                    });
                    return;
                  }
                  if (isHandleModel) {
                    history.push({
                      pathname: '/catalog',
                      state: {
                        isModel: true,
                      },
                    });
                    return;
                  }
                  if (isHandleVersion) {
                    history.push({
                      pathname: '/catalog',
                      state: {
                        isVersion: true,
                      },
                    });
                    return;
                  }
                  history.push('/events');
                }}
              >
                Retour
              </Button>
            </div>
          ) : (
            <div></div>
          )}
        <ToggleMenu />
      </Grid>
    </Toolbar>
  );
};

const NavigationNonAuth = () => {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      <Grid item>
        <img className={classes.logo} src={assets.logo} alt="Porsche logo" />
      </Grid>
    </Grid>
  );
};

export default () => {
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);
  return (
    <div className={classes.grow}>
      <AppBar position="static" color="inherit" className={classes.appBar}>
        {authUser ? <NavigationAuth /> : <NavigationNonAuth />}
      </AppBar>
    </div>
  );
};
