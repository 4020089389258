import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext } from '../../components/Session';
import * as ROUTES from '../../constants/routes';
import get from '../../utils/get';

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = 'Veuillez saisir votre mot de passe.';
  }
  if (!values.email) {
    errors.email = 'Veuillez saisir un Porsche ID valide';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Veuillez saisir un Porsche ID valide';
  }
  return errors;
};

const SignInPage = () => <SignInForm />;

const INITIAL_STATE = {};

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function Transition(props) {
  return <Slide {...props} direction="left" />;
}

function SignInFormBase(props) {
  const classes = useStyles();
  const [error, setError] = useState(INITIAL_STATE);
  const [transition, setTransition] = useState();
  const authUser = useContext(AuthUserContext);
  const [open, setOpen] = useState(false);
  const resetPasswordEmail = get(props, 'match.params.resetPassword', '');
  const handleShowSuccessMsg = () => {
    setTransition(() => Transition);
    setOpen(true);
  };
  const handleHideSuccessMsg = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  useEffect(
    () => {
      if (authUser) {
        props.history.push(ROUTES.MAIN)
      }
      if(resetPasswordEmail.length) {
        handleShowSuccessMsg()
      }
    }, []) // eslint-disable-line

  const formik = useFormik({
    initialValues: {
      password: '',
      email: resetPasswordEmail,
    },
    validate,
    onSubmit: ({ email, password }) => {
      props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(() => {
          setError(INITIAL_STATE);
        })
        .catch((err) => {
          const translatedError = err;
          if (err.code === 'auth/weak-password')
            translatedError.message = `Les mots de passe forts ont au moins 6 caractères de lettres et de chiffres`;
          if (err.code === 'auth/wrong-password')
            translatedError.message = `L'identifiant ou le mot de passe saisi n'est pas valide`;
          if (err.code === 'auth/invalid-action-code')
            translatedError.message = `Le mot de passe non valide ou l'utilisateur n'a pas de mot de passe`;
          if (err.code === 'auth/too-many-requests')
            translatedError.message = `Suite à plusieurs tentatives erronées, votre compte a été bloqué. Veuillez contacter l'administrateur.`;
          if (err.code === 'auth/invalid-display-name')
            translatedError.message = `email ou password saisis non valide`;
          if (err.code === 'auth/invalid-email')
            translatedError.message = `email saisi non valide`;
          if (err.code === 'auth/user-not-found')
            translatedError.message = `Les données de connexion saisies ne sont pas correctes`;
          setError(translatedError);
        });
    },
  });
  
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Se connecter
        </Typography>
        <form
          className={classes.form}
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Identifiant (adresse e-mail)"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email ? formik.errors.email : ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={formik.handleChange}
            value={formik.values.password}
            helperText={formik.touched.password ? formik.errors.password : ''}
            error={formik.touched.password && Boolean(formik.errors.password)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Rester connecté(e)"
          />
          {error && <Typography color="error">{error.message}</Typography>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
          >
            Se connecter
          </Button>
          <Button
            color="primary"
            onClick={() => props.history.push('/resetPassword')}
          >
            Mot de passe oublié ?
          </Button>
        </form>
      </div>
      <Snackbar
        TransitionComponent={transition}
        open={open}
        autoHideDuration={6000}
        onClose={handleHideSuccessMsg}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleHideSuccessMsg}
          severity="success"
        >
          Votre mot de passe a été changé avec succès
        </Alert>
      </Snackbar>
    </Container>
  );
}

const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;

export { SignInForm };

