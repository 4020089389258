import React, { useEffect, useState, useCallback, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useFormik } from 'formik';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import passwordGenerate from '../../utils/generate';
import { AuthUserContext } from '../Session';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2),
    textTransform: "none",
  },
  title: {
    margin: theme.spacing(0, 0, 2, 1),
    textTransform: "none",
  },
  rectangle: {
    height: "56px",
    padding: "4px 2px",
    border: '1px solid #000000',
    textTransform: "none",
    backgroundColor: 'white',
  },
  formControl: {
    '& Label': {
      background: '#fff',
      padding: '0 5px',
    },
  },
}));

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Ce champ est obligatoire';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
  } else if (values.email.length < 6) {
    errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
  } else if (values.email.length > 100) {
    errors.email = 'Veuillez saisir un identifiant valide (e-mail).';
  }
  if (!values.porscheCenters) {
    errors.porscheCenters = 'Veuillez sélectionner au moins un centre Porsche.';
  } else if (!values.porscheCenters.length) {
    errors.porscheCenters = 'Veuillez sélectionner au moins un centre Porsche.';
  }
  return errors;
};

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';
const ERROR_MSG_ACCOUNT_EXISTS =
  "L'identifiant choisi existe déjà. Veuillez en choisir un autre.";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddAgentForm = ({
  firebase,
  getList,
  handleShowSuccessMsg,
  onClose,
  type,
  templates,
}) => {
  const classes = useStyles();
  const [userError, setUserError] = useState({});
  const [porscheCentersList, setPorscheCenters] = useState([]);
  const [displayHiddenPassword, setDisplayHiddenPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const authUser = useContext(AuthUserContext);

  const getCenterPorsches = useCallback(async () => {
    if (authUser && authUser.role === 'ADMIN') {
      const querySnapshot = await firebase.porscheCenters().get();
      const porscheCentersComputedData = querySnapshot.docs.map((doc) => { const docData = doc.data(); return docData.porscheCenterName })
      if (!porscheCentersList.length) {
        setPorscheCenters(porscheCentersComputedData);
      }
    } else {
      setPorscheCenters(authUser.porscheCenters);
    }
  }, [porscheCentersList.length]); //eslint-disable-line


  useEffect(() => {
    getCenterPorsches();
  }, []); //eslint-disable-line

  const formik = useFormik({
    initialValues: {
      email: '',
      porscheCenters: [],
      idTemplate: '',
      idTemplateEvent: '',
    },
    validate,
    /* idTemplateEvent: représente la template pdf rattaché à l’agent utilisé pour la partie event lors du création ou update guest, et il sera remplie par les informations
     nécessaires et uploader vers le storage, afin de le récupérer dans la page détail event on cliquant sur le button “export consentement et decharge”
    idTemplate: représente la template pdf rattaché à l’agent et vendeur pour la partie essai seulement */
    onSubmit: async ({ email, porscheCenters, idTemplate, idTemplateEvent }, { resetForm }) => {
      if (passwordValue.length) {
        try {
          const authAgent = await firebase.doCreateUserWithEmailAndPassword(
            email,
            passwordValue
          );
          await firebase.doResetPassword(email, {
            url: `${process.env.REACT_APP_EMAIL_REDIRECT}/signin/${email}`,
            handleCodeInApp: false,
          })
          await firebase.user(authAgent.user.uid).set(
            {
              porscheCenters,
              email,
              role: type,
              idTemplate,
              idTemplateEvent,
            },
            { merge: true }
          );
          await getList();
          handleShowSuccessMsg(email);
          await resetForm({
            email: '',
            porscheCenters: [],
            idTemplate,
            idTemplateEvent,
          });
          onClose();
        } catch (error) {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            setUserError({ ...error, message: ERROR_MSG_ACCOUNT_EXISTS });
          }
        }
      } else {
        setUserError({ ...userError, message: `Veuillez cliquez sur 'Générer un mot de passe'` });
      }
    },
  });
  return (
    <form
      className={classes.form}
      noValidate
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
    >
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        className={classes.title}
      >
        Ajouter un {type === 'AGENT' ? 'agent' : 'vendeur'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Adresse e-mail"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
            helperText={formik.touched.email ? formik.errors.email : ''}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {
            !displayHiddenPassword
              ?
              (
                <Button
                  id="generatePassword"
                  type="button"
                  fullWidth
                  variant="contained"
                  className={classes.rectangle}
                  onClick={() => {
                    setDisplayHiddenPassword(true);
                    setUserError({ ...userError, message: '' });
                    setPasswordValue(passwordGenerate(100, 8));
                  }}
                >
                  Générer un mot de passe
                </Button>
              )
              :
              (
                <TextField
                  id="randomPassword"
                  variant="outlined"
                  required
                  fullWidth
                  name="randomPassword"
                  label="Mot de passe"
                  type="password"
                  autoComplete="current-password"
                  autoFocus
                  value={passwordValue}
                />
              )
          }
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" style={{width: "100%"}}  className={classes.formControl}>
            <InputLabel htmlFor="outlined-template-native-simple">Sélectionner un template</InputLabel>
            <Select
              value={formik.values.idTemplate}
              onChange={formik.handleChange}
              inputProps={{
                name: 'idTemplate',
                id: 'outlined-template-native-simple',
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem aria-label="None" value="" style={{padding: "20px"}} />
              {templates.map((elem) => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.currentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Grid>
        {type === 'AGENT' && 
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" style={{width: "100%"}}  className={classes.formControl}>
              <InputLabel htmlFor="outlined-template-event-native-simple">Sélectionner un template d'événement</InputLabel>
              <Select
                value={formik.values.idTemplateEvent}
                onChange={formik.handleChange}
                inputProps={{
                  name: 'idTemplateEvent',
                  id: 'outlined-template-event-native-simple',
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem aria-label="None" value="" style={{padding: "20px"}} />
                {templates.map((elem) => (
                  <MenuItem key={elem.id} value={elem.id}>
                    {elem.currentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
        

        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            id="size-small-filled-multi"
            size="medium"
            onChange={(_, value) =>
              formik.setFieldValue('porscheCenters', value)
            }
            value={formik.values.porscheCenters}
            options={porscheCentersList}
            disableCloseOnSelect
            renderOption={(center, { selected }) => (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {center} </> )}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Centre(s) Porsche"
                placeholder="Selectionner un centre Porsche"
                fullWidth
                helperText={
                  formik.touched.porscheCenters
                    ? formik.errors.porscheCenters
                    : ''
                }
                error={
                  formik.touched.porscheCenters &&
                  Boolean(formik.errors.porscheCenters)
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          {userError && (
            <Typography color="error">{userError.message}</Typography>
          )}
        </Grid>
      </Grid>
      <Grid container justify="space-around">
        <Grid item>
          <Button
            type="reset"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.submit}
            onClick={() => onClose()}
          >
            Fermer
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={formik.handleSubmit}
          >
            Enregistrer
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default compose(withFirebase)(AddAgentForm);
