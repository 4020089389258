/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import readXlsxFile from 'read-excel-file';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, LinearProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import axios from 'axios';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import TopPanel from './Panel/PanelCatalog';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    margin: '30px 20px 0px 30px',
    position: 'relative',
  },
  title: {
    paddingBottom: '30px',
  },
  label: {
    cursor: 'pointer',
    backgroundColor: '#313639',
    color: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontSize: '0.95rem',
    minWidth: '64px',
    padding: '6px 16px',
    boxSizing: 'border-box',
    fontFamily: 'PorscheNextTT, sans-serif',
    fontWeight: 700,
    lineHeight: 1.75,
    borderRadius: '4px',
  },
  input: {
    opacity: 0,
    zIndex: -1,
    width: '10px',
  },
  blocBtn: {
    position: 'absolute',
    right: '15px',
    zIndex: '2',
  },
}));

const Catalog = ({ firebase, history }) => {
  const [state, setState] = useState({
    errorFormat: '',
    load: false,
    alert: {
      msg: '',
      type: 'error',
      show: false,
    },
  });

  const fileValid = (res) => {
    const headerString = ['Code modèle', 'Primary_Interest', 'Gamme', 'Modèle', 'Version', 'Ordre'];
    const isValid =
      JSON.stringify(headerString).toLowerCase() === JSON.stringify(res).toLowerCase();
    return isValid;
  };

  const importModels = async (e) => {
    setState({ ...state, load: true, errorFormat: '' });
    const { files } = e.target;

    if (files && files[0]) {
      const result = await readXlsxFile(files[0]);
      if (
        files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        !fileValid(result[0])
      ) {
        const msg =
          'le format du fichier que vous essayez d’importer - et/ou son contenu n’est pas valide';
        setState({ ...state, load: false, alert: { show: true, msg, type: 'error' } });
      } else {
        const formData = new FormData();
        const file = new Blob([files[0]]);
        formData.append('xlsxImport', file, file.filename);
        firebase.auth.currentUser
          .getIdToken(true)
          .then((token) => {
            axios
              .post(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/processCatalogImport`, formData, {
                params: {},
                headers: { Authorization: `Bearer ${token}` },
              })
              .then((response) => {
                console.log('response', response);
                // avoid constant re-render of the list once the import is done
                window.location.reload();
              })
              .catch((error) => {
                console.log('Error during import of catalog', error);
                const msg =
                  'Une erreur s’est produite lors de l’import. Veuillez réessayer ou contacter un responsable technique';
                setState({ ...state, load: false, alert: { show: true, msg, type: 'error' } });
              });
          })
          .catch((error) => {
            console.log('Error getting refreshed Firebase Token', error);
          });
      }
    }
  };

  const classes = useStyles();
  const { load, alert } = state;

  return (
    <div className={classes.root}>
      {load && (
        <div>
          <div style={{ margin: '20px 0' }}>
            <LinearProgress />
          </div>
          <div>
            <LinearProgress
              color="secondary"
              onClose={() => {
                setState({ ...state, load: false });
              }}
            />
          </div>
        </div>
      )}
      <div>
        <Grid container item xs={12} className={classes.title}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Box fontSize={36} fontWeight="bold" letterSpacing={0}>
                Catalogue
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.blocBtn}>
          <label htmlFor="xlsxImport" className={classes.label}>
            Importer catalogue
          </label>
          <input
            type="file"
            id="xlsxImport"
            name="xlsxImport"
            accept=".xlsx, .xls"
            className={classes.input}
            onChange={importModels}
          />
        </div>
        <TopPanel history={history} />
        {alert.show && (
          <Alert
            severity="error"
            style={{ width: '50%', position: 'absolute', top: 20, right: 20 }}
            onClose={() => {
              setState({ ...state, alert: { show: false, msg: '' } });
            }}
          >
            {alert.msg}
          </Alert>
        )}
      </div>
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(Catalog);
