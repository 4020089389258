/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Box,
  LinearProgress,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  Fab,
  TableHead,
  TableRow,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Button,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import readXlsxFile from 'read-excel-file';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { compose } from 'recompose';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import { withFirebase } from '../../components/Firebase';
import { SearchInput } from '../../shared/Layout/SearchInput';
import { withAuthorization } from '../../components/Session';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
    '&:first-child': {
      cursor: 'pointer',
    },
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: '30px 20px 0px 30px',
  },
  table: {
    minWidth: 700,
  },
  label: {
    cursor: 'pointer',
    backgroundColor: '#d5001c',
    color: '#fff',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    fontSize: '0.95rem',
    minWidth: '64px',
    padding: '14px 20px',
    boxSizing: 'border-box',
    fontFamily: 'PorscheNextTT, sans-serif',
    fontWeight: 700,
    lineHeight: 1.75,
    borderRadius: '4px',
  },
  input: {
    opacity: 0,
    width: '10px',
  },
  title: {
    paddingBottom: '30px',
  },
  blocSearchBtn: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  blocBtn: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'none',
    paddingBottom: '30px',
  },
  cellhead: {
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '23px',
    '& th:nth-child(1)': {
      width: '150px',
    },
    '& th:nth-child(2)': {
      width: '250px',
    },
  },
  chip: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  Fab: {
    width: '35px',
    height: '30px',
    marginLeft: '15px',
    marginTop: '5px',
  },
  msgText: {
    paddingTop: '20px',
  },
  modal: {
    maxWidth: '536px',
    margin: '0 auto',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '36px',
    textAlign: 'center',
    textTransform: 'unset',
  },
  modalContent: {
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '23px',
    padding: '0 20px',
  },
  modalAction: {
    padding: '8px 40px',
    justifyContent: 'space-between',
    marginBottom: '40px',
  },
  modalCancelBtn: {
    backgroundColor: '#313639',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#313639',
      color: 'white',
    },
  },
  modalSubmitBtn: {
    backgroundColor: '#D5001C',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'unset',
    color: 'white',
    padding: '10px 20px',
    position: 'relative',
    '&:hover': {
      backgroundColor: '#D5001C',
      color: 'white',
    },
  },
  iconClose: {
    position: 'absolute',
    right: '20px',
    top: '17px',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px',
  },
}));

const PorscheCenterPage = ({ firebase }) => {
  const [centers, setCenters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchedWord, setSearchedWord] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedCenter, setSelectedCenter] = React.useState(null);
  const [state, setState] = useState({
    load: false,
    alert: {
      msg: '',
      type: 'error',
      show: false,
    },
  });

  const [alert, setAlert] = React.useState({
    isOpen: false,
    msg: '',
  });

  const classes = useStyles();

  function filterByCenter(center) {
    const searchByName =
      searchedWord.length > 0
        ? center.porscheCenterName.toLowerCase().indexOf(searchedWord) > -1
        : true;
    const searchByCode =
      searchedWord.length > 0
        ? center.porscheCenterCode.toLowerCase().indexOf(searchedWord) > -1
        : true;
    const searchByEmails =
      searchedWord.length > 0
        ? center.emails
          .toString()
          .toLowerCase()
          .indexOf(searchedWord) > -1
        : true;
    return searchByName || searchByCode || searchByEmails;
  }

  const centerSearch = () => centers.filter(filterByCenter);

  const getCenters = React.useCallback(async () => {
    setLoading(true);
    const centersSnapshot = await firebase.db.collection('porsche-centers').orderBy('porscheCenterName', 'asc').get();
    if (!centersSnapshot.empty) {
      const centersList = [];
      centersSnapshot.forEach((doc) =>
        centersList.push({
          ...doc.data(),
          key: doc.id,
        }),
      );
      setCenters(centersList);
      setLoading(false);
      return;
    }
    setCenters([]);
    setLoading(false);
  }, [firebase.db]);

  useEffect(() => {
    getCenters();
  }, [getCenters]);

  const fileValid = (res) => {
    const headerString = ['Code', 'Nom', 'Emails', 'Codes_Postaux'];
    const isValid =
      JSON.stringify(headerString).toLowerCase() === JSON.stringify(res).toLowerCase();
    return isValid;
  };

  const importCenters = async (e) => {
    setState({ ...state, load: true });
    const { files } = e.target;
    if (files && files[0]) {
      readXlsxFile(files[0]).then((result) => {
        if (
          files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          !fileValid(result[0])
        ) {
          const msg =
            'le format du fichier que vous essayez d’importer - et/ou son contenu n’est pas valide';
          setState({ ...state, load: false, alert: { show: true, msg, type: 'error' } });
        } else {
          const formData = new FormData();
          const file = new Blob([files[0]]);
          formData.append('xlsxImport', file, file.filename);
          firebase.auth.currentUser
            .getIdToken(true)
            .then((token) => {
              axios
                .post(`${process.env.REACT_APP_IMPORT_EXPORT_URL}/processCentersImport`, formData, {
                  params: {},
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  console.log('response', response);
                  setState({ ...state, load: false });
                  // avoid constant re-render of the list once the import is done
                  window.location.reload();
                })
                .catch((error) => {
                  console.log('Error during import of centres porsche', error);
                  const msg =
                    'Une erreur s’est produite lors de l’import. Veuillez réessayer ou contacter un responsable technique';
                  setState({ ...state, load: false, alert: { show: true, msg, type: 'error' } });
                });
            })
            .catch((error) => {
              console.log('Error getting refreshed Firebase Token', error);
            });
        }
      });
    }
  };
  const { load } = state;

  const handleOpenModal = (center) => {
    setSelectedCenter(center);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsDeleteModalOpen(false);
  };

  function handleCloseAlert() {
    setAlert({
      isOpen: false,
      msg: '',
    });
  }
  const DELETE_ERROR_MSG = 'Désolé, ce centre n’a pas pu être supprimé';
  const DELETE_ERROR_ATTACHED_EVENT =
    'Un event en cours est rattaché à ce centre. Il ne peut être supprimé';
  const DELETE_ERROR_ATTACHED_AGENT =
    'Des agents sont rattachés à ce centre. Il ne peut être supprimé';
  const DELETE_ERROR_ATTACHED_EVENT_AGENT =
    'Des agents et des événements sont rattachés à ce centre. Il ne peut être supprimé';

  const attachedEventCenter = async (centerName) => {
    const eventRef = firebase.db.collection('events');
    const centerEventSnapshot = await eventRef
      .where('porscheCenters', 'array-contains', centerName)
      .limit(1)
      .get();

    return centerEventSnapshot.docs;
  };

  const attachedAgentCenter = async (centerName) => {
    const agentRef = firebase.db.collection('users');
    const centerAgentSnapshot = await agentRef
      .where('porscheCenters', 'array-contains', centerName)
      .limit(1)
      .get();

    return centerAgentSnapshot.docs;
  };
  async function handleDelete() {
    try {
      const centerRef = firebase.db.collection('porsche-centers');
      const events = await attachedEventCenter(selectedCenter?.porscheCenterName);
      const agents = await attachedAgentCenter(selectedCenter?.porscheCenterName);
      if (events.length > 0 && agents.length > 0) {
        setAlert({
          isOpen: true,
          msg: DELETE_ERROR_ATTACHED_EVENT_AGENT,
        });
        setIsDeleteModalOpen(false);
        return;
      }
      if (agents.length > 0) {
        setAlert({
          isOpen: true,
          msg: DELETE_ERROR_ATTACHED_AGENT,
        });
        setIsDeleteModalOpen(false);
        return;
      }
      if (events.length > 0) {
        setAlert({
          isOpen: true,
          msg: DELETE_ERROR_ATTACHED_EVENT,
        });
        setIsDeleteModalOpen(false);
        return;
      }

      if (events.length === 0 && agents.length === 0) {
        centerRef
          .doc(selectedCenter?.key)
          .delete()
          .then(async () => {
            try {
              getCenters();
              handleCloseModal();
            } catch {
              setAlert({
                isOpen: true,
                msg: DELETE_ERROR_MSG,
              });
            }
          });
      }
    } catch (err) {
      handleCloseModal();
      setAlert({
        isOpen: true,
        msg: DELETE_ERROR_MSG,
      });
    }
  }

  return (
    <div className={classes.root}>
      {load && (
        <div>
          <div style={{ margin: '20px 0' }}>
            <LinearProgress />
          </div>
          <div>
            <LinearProgress color="secondary" />
          </div>
        </div>
      )}
      <Grid container item xs={12} className={classes.title}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box fontSize={36} fontWeight="bold" letterSpacing={0}>
              Centres Porsche
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.blocSearchBtn}>
        <SearchInput
          setSearchFunction={setSearchedWord}
          maxLength={50}
          label="Rechercher un centre Porsche"
        />
        <div className={classes.blocBtn}>
          <label htmlFor="xlsxImport" className={classes.label}>
            Importer Centres Porsche
          </label>
          <input
            type="file"
            id="xlsxImport"
            name="xlsxImport"
            accept=".xlsx, .xls"
            className={classes.input}
            onChange={importCenters}
          />
        </div>
      </div>
      {state.alert.show && (
        <Alert
          severity="error"
          style={{ width: '50%', position: 'absolute', top: '140px', right: 20 }}
          onClose={() => {
            setState({ ...state, alert: { show: false, msg: '' } });
          }}
        >
          {state.alert.msg}
        </Alert>
      )}
      {alert.isOpen && (
        <Alert
          severity="error"
          style={{ width: '50%', position: 'absolute', top: '100px', right: 20 }}
          onClose={handleCloseAlert}
        >
          {alert.msg}
        </Alert>
      )}
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress disableShrink color="secondary" />
        </div>
      ) : centers.length > 0 ? (
        centerSearch(centers).length > 0 ? (
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.cellhead}>
                  <StyledTableCell className={classes.cellhead} align="left">
                    Code
                  </StyledTableCell>
                  <StyledTableCell className={classes.cellhead} align="left">
                    Nom
                  </StyledTableCell>
                  <StyledTableCell className={classes.cellhead} align="left">
                    Adresse Email
                  </StyledTableCell>
                  <StyledTableCell className={classes.cellhead} align="center">
                    Actions
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {centerSearch(centers).map((center, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell align="left">{center.porscheCenterCode}</StyledTableCell>
                    <StyledTableCell align="left">{center.porscheCenterName}</StyledTableCell>
  
                    <StyledTableCell align="left" className={classes.chip}>
                      {center.emails &&
                        center.emails.map(
                          (item) => !!item && <Chip label={item} variant="outlined" key={item} />,
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div>
                        <Fab className={classes.Fab} color="primary" aria-label="view">
                          <DeleteIcon onClick={() => handleOpenModal(center)} />
                        </Fab>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            <Dialog
              open={isDeleteModalOpen && !!selectedCenter}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className={classes.modal}
            >
              <IconButton
                className={classes.iconClose}
                aria-label="close"
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
              <p className={classes.modalTitle} id="alert-dialog-title">
                Confirmation de suppression
              </p>
              <DialogContent>
                <p className={classes.modalContent} id="alert-dialog-description">
                  Souhaitez-vous vraiment supprimer le centre {selectedCenter?.porscheCenterName}.
                </p>
              </DialogContent>
              <DialogActions className={classes.modalAction}>
                <Button onClick={handleCloseModal} className={classes.modalCancelBtn}>
                  Annuler
                </Button>
                <Button onClick={handleDelete} className={classes.modalSubmitBtn}>
                  &gt;&nbsp; Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </TableContainer>
        ) : (
          <div className={classes.msgText}>Aucun résultat ne correspond à votre recherche</div>
        )
      ) : (
        <div className={classes.msgText}>Aucun centre Porsche importé</div>
      )}
    </div>
  );
};

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(PorscheCenterPage);
