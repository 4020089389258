import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(() => ({
  search: {
    maxWidth: '544px',
    width: '100%',
    marginBottom: '40px',
  },
  searchInput: {
    width: '80%',
  },
}));


const SearchInput = ({ setSearchFunction, label , maxLength}) => {
  const classes = useStyles();

  return (
    <div className={classes.search}>
      <TextField
        className={classes.searchInput}
        label={label}
        type="search"
        inputProps={{ maxLength }}
        onChange={(event) => setSearchFunction(event.target.value.toLowerCase())}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search">
        <SearchIcon />
      </IconButton>
    </div>
  );
};


export default SearchInput;
