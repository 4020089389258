import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Popover, Typography, Divider, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import SignOutButton from '../../../components/SignOut';
import { AuthUserContext, withAuthorization } from '../../../components/Session'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    marginTop: '14px',
  },
  listItem: {
    minHeight: '65px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    minWidth: '250px',
  },
  typo: {
    fontWeight: 600,
  },
}));

const MenuListComposition = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authUser = useContext(AuthUserContext);
  const userRole = {
    ADMIN : "Administrateur",
    AGENT : "Agent",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
      >
        <PermIdentityIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.paper}
      >
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Typography variant="subtitle2" display="block" gutterBottom onClick={handleClose}> Identifiant : </Typography>
            <Typography onClick={handleClose}>{authUser.email} </Typography>
          </ListItem>
          <Divider variant="middle" />
          <ListItem className={classes.listItem}>
            <Typography variant="subtitle2" display="block" gutterBottom> Profil : </Typography>
            <Typography onClick={handleClose}> {userRole[authUser.role] || '' } </Typography>
          </ListItem>
          <Divider variant="middle" />
          <ListItem className={classes.listItem}>
            <SignOutButton />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withRouter)
(MenuListComposition);