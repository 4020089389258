import get from 'lodash/get'
import { Button, Checkbox, FormControlLabel, Paper, TableContainer, Table, TableBody, Grid, Box, TableHead, TableRow, TextField } from '@material-ui/core';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { compose } from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { withFirebase } from '../../components/Firebase';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import TableRowEvent from './components/TableRowEvent';
import { isAfterOrSame, isBeforeOrSame, isBefore, isAfter } from '../../utils/dateStringComparison';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableSortLabel = withStyles(() =>
  createStyles({
    root: {
      backgroundColor: "black",
      color: 'white',
      "&:hover": {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  })
)(TableSortLabel);

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: 'name', label: 'Nom de l’évènement' },
    { id: 'campagneCode', label: 'Code campagne'},
    { id: 'type', label: 'Statut' },
    { id: 'creationDate', label: 'Date de création' },
    { id: 'startDate', label: 'Date de début' },
    { id: 'endDate', label: 'Date de fin' },
    { id: 'size', label: 'Invités' },
    { label: 'Actions' },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="center"
            className={classes.tablecell}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </StyledTableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "30px 20px 0px 30px",
  },
  ArrowDown: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  table: {
    minWidth: 700,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tablecell: {
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: 0,
    backgroundColor: 'black',
    width: 150,
    padding: '7px 0px 5px 27px',
  },
  iconButton: {
    padding: 10,
  },
  searchInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
  },
}));

const Events = ({ firebase, history }) => {
  const [events, setEvents] = useState([]);
  const [searchEvent, setSearchEvent] = useState([]);
  const [searchEventName, setSearchEventName] = useState('');
  const [state, setState] = useState({
    checkedOnGoing: false,
    checkedFutur: false,
    checkedPast: false,
  });
  const [eventType, setEventType] = useState([]);
  const authUser = useContext(AuthUserContext);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('creationDate');

  const asyncFetch = async () => {
    const eventsRef = firebase.db.collection('events');
    const eventList = authUser.role === 'ADMIN'
      ? await eventsRef.orderBy('startDate', 'asc').get()
      : await eventsRef
        .where('porscheCenters', 'array-contains-any', authUser.porscheCenters)
        .orderBy('startDate', 'asc')
        .get();

    const promises = eventList.docs.map(async (event) => {
      const docData = event.data();
      const { porscheCenters } = docData;
      const size = get(docData, 'totalGuests', 0)
      if (authUser.porscheCenters) {
        const exist = porscheCenters.filter((center) => !authUser.porscheCenters.includes(center));
        const existed = !exist.length;
        return { ...docData, size, existed, id: event.id };
      }
      return { ...docData, size, existed: false, id: event.id };
    });
    const data = (await Promise.all(promises)).filter(Boolean);


    const onGoingEventsRightTs = data.map((event) => (
      { ...event, 
        startDate: moment.tz(event.startDate.toDate(), "Europe/Paris").format(),
        endDate: moment.tz(event.endDate.toDate(), "Europe/Paris").format(),
        creationDate: moment.tz(event.creationDate, "Europe/Paris").format(),
      }));
    const momentTodayDate = moment().tz("Europe/Paris").format();

    const onGoingEvents = onGoingEventsRightTs.filter((event) => isBeforeOrSame(event.startDate, momentTodayDate) && isAfterOrSame(event.endDate, momentTodayDate));
    const onGoingEventsType = onGoingEvents.map((event) => ({ ...event, type: 'En Cours', id: event.id }));

    const pastEventsRightTs = data.map((event) => (
      { ...event, 
        startDate: moment.tz(event.startDate.toDate(), "Europe/Paris").format(),
        endDate: moment.tz(event.endDate.toDate(), "Europe/Paris").format(),
        creationDate: moment.tz(event.creationDate, "Europe/Paris").format(),
      }));

    const pastEvents = pastEventsRightTs.filter((event) => isBefore(event.startDate, momentTodayDate) && isBefore(event.endDate, momentTodayDate));

    const pastEventsType = pastEvents.map((event) => ({ ...event, type: 'Passé', id: event.id }));

    const futureEventsRightTs = data.map((event) => (
      { ...event, 
        startDate: moment.tz(event.startDate.toDate(), "Europe/Paris").format(),
        endDate: moment.tz(event.endDate.toDate(), "Europe/Paris").format(),
        creationDate: moment.tz(event.creationDate, "Europe/Paris").format(),

      }));
    const futureEvents = futureEventsRightTs.filter((event) => isAfter(event.startDate, momentTodayDate) && isAfter(event.endDate, momentTodayDate));
  
    const futureEventsType = futureEvents.map((event) => ({ ...event, type: 'Futur', id: event.id }));

    setEvents([...onGoingEventsType, ...futureEventsType, ...pastEventsType]);
    setSearchEvent([...onGoingEventsType, ...futureEventsType, ...pastEventsType]);
  };

  useEffect(() => {
    asyncFetch();
  }, []); // eslint-disable-line

  useEffect(() => {
    const data = [];
    eventType.map((item) => data.push(...getEventByType(item)));
    if (eventType.length === 0) {
      if (searchEventName) {
        filterList(searchEventName, events);
      } else {
        setSearchEvent(events);
      }
    } else if (searchEventName) {
      filterList(searchEventName, data);
    } else {
      setSearchEvent(data);
    }

  },[eventType, searchEventName]); // eslint-disable-line

  const filterList = (event, filteredEventsData) => {
    let items = filteredEventsData;
    items = items.filter((item) => {
      if (item.name.toLowerCase().indexOf(event.toLowerCase()) !== -1) {
        return true;
      }
      if (item.campagneCode.toLowerCase().indexOf(event.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    });
    setSearchEvent(items);
  };

  const getEventByType = (item) => {
    const result = events.filter((e) => e.type === item)
    return result;
  };

  const handleChange = (name) => (event) => {
    setState({ ...state, [event.target.value]: event.target.checked });
    const index = eventType.indexOf(name);
    if (index > -1) {
      setEventType(eventType.filter((item) => item !== name));
    } else {
      setEventType((previous) => ([...previous, name]));
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={3}
      >
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Box fontSize={36} fontWeight="bold" letterSpacing={0}>Évènements</Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between" item xs={12} spacing={3}>
          <Grid item xs={5} style={{marginRight: '-24px'}}>
            <Paper className={classes.search}>
              <TextField className={classes.searchInput} style={{marginBottom: '10px'}} label="Rechercher un évènement" type="search" onChange={(event) => setSearchEventName(event.target.value.toLowerCase())} fullWidth/>
              <IconButton type="submit" className={classes.iconButton} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
          <Grid  
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end" item xs={6}>
            <Button variant="contained" color="secondary" onClick={() => history.push('/add-event')}>
                Créer un évènement
            </Button>
          </Grid>

        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <Paper component="form" className={classes.search} elevation={0}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedPast} onChange={handleChange('Passé')} value="checkedPast" />
                  }
                  label="Passé"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedOnGoing} onChange={handleChange('En Cours')} value="checkedOnGoing" />
                  }
                  label="En Cours"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedFutur} onChange={handleChange('Futur')} value="checkedFutur" />
                  }
                  label="Futur"
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(searchEvent, getComparator(order, orderBy)).map((event) => <TableRowEvent asyncFetch={asyncFetch} key={event.id} event={event} history={history} status={event.type} role={authUser.role} events={events} setEvents={() => setEvents()} />)
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

Events.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const condition = (authUser) => !!authUser;
export default compose(withFirebase, withAuthorization(condition), withRouter)(Events);
