/* eslint-disable no-console */
import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import VersionForm from '../../components/VersionForm';
import calculOrderVersion from '../../helpers/calculOrderVersion';

const AddVersionForm = ({ firebase, history }) => {
  const [isLoading, setIsLoading] = useState(false);

  const redirect = () => {
    setIsLoading(false);
    history.push({
      pathname: '/catalog',
      state: {
        isVersion: true,
      },
    });
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const order = await calculOrderVersion(firebase, values.modelId);
    await firebase.db
      .collection('porsche-versions')
      .add({
        name: values.versionName,
        model_id: values.modelId,
        codeModel: values.codeModel,
        primary_interest: values.primaryInterest,
        order,
      })
      .then(async (docRef) => {
        if(values.fileUrl){
          const path = `versions/`;
          const storageRef = firebase.storage.ref(`${path}/${docRef.id}`);
          const blob = await fetch(values.fileUrl).then((r) => r.blob());
          storageRef
            .put(blob)
            .then(async (snapshot) => {
              const url = await snapshot.ref.getDownloadURL();
              firebase.db
                .collection('porsche-versions')
                .doc(docRef.id)
                .update({ url })
                .then(() => redirect())
                .catch((e) => console.error('Error when linking', e));
            })
            .catch((e) => console.error('Error when uploading', e));
          return;
        }
        redirect()
      })
      .catch((e) => console.error('Error when adding new version', e));
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.versionName.length || values.versionName?.trim().length === 0) {
      errors.versionName = 'Veuillez renseigner le nom de la version';
    }
    if (!values.modelId.length || values.modelId?.trim().length === 0) {
      errors.modelId = 'Veuillez sélectionner le modèle';
    }
    if (!values.codeModel.length || values.codeModel?.trim().length === 0) {
      errors.codeModel = 'Veuillez renseigner le code du modèle';
    }
    if (!values.primaryInterest.length || values.primaryInterest?.trim().length === 0) {
      errors.primaryInterest = 'Veuillez renseigner le primary Interest de la version';
    }
    return errors;
  };
  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        versionName: '',
        modelId: '',
        codeModel: '',
        primaryInterest: '',
        fileUrl: '',
      }}
      validate={validateValues}
      onSubmit={(
        { versionName, modelId, codeModel, primaryInterest, fileUrl },
        { setSubmitting },
      ) =>
        handleSubmit(
          {
            versionName,
            modelId,
            codeModel,
            primaryInterest,
            fileUrl,
          },
          { setSubmitting },
        )
      }
    >
      {(formik) => (
        <Container component="main">
          <VersionForm
            formik={formik}
            isLoading={isLoading}
            history={history}
            firebase={firebase}
            handleSubmit={handleSubmit}
            mutation="ADD"
          />
        </Container>
      )}
    </Formik>
  );
};

const condition = (authUser) => !!authUser;

const AddVersionFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(AddVersionForm);

AddVersionForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddVersionFormContainer;