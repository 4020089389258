import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { LinearProgress, Container } from '@material-ui/core';
import { withFirebase } from '../../components/Firebase';
import { withAuthorization } from '../../components/Session';
import VersionForm from '../../components/VersionForm';

const EditModelForm = ({ firebase, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const { state } = history.location;
    setInitialValues(state);
  }, [initialValues, history.location]);

  const updateDocument = async (data) => {
    await firebase.db
      .collection('porsche-versions')
      .doc(initialValues.key)
      .set(
        {
          ...data,
        },
        { merge: true },
      )
      .then(() => {
        setIsLoading(false);
        history.push({
          pathname: '/catalog',
          state: {
            isVersion: true,
          },
        });
      })
      .catch((e) => {
        console.error('Error when updating version', e);
      });
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    if (values.fileUrl === initialValues.versionURL) {
      updateDocument({
        name: values.versionName,
        model_id: values.modelId,
        codeModel: values.codeModel,
        primary_interest: values.primaryInterest,
      });
      return;
    }
    const storageRef = firebase.storage.ref(`versions/${initialValues.key}`);
    if (values.versionURL) {
      storageRef
        .delete()

        .then(async () => {
          if(values.fileUrl){
            const blob = await fetch(values.fileUrl).then((r) => r.blob());
            storageRef
              .put(blob)
              .then(async (snapshot) => {
                const url = await snapshot.ref.getDownloadURL();
                updateDocument({
                  url,
                  name: values.versionName,
                  model_id: values.modelId,
                  codeModel: values.codeModel,
                  primary_interest: values.primaryInterest,
                });
              })
              .catch((e) => {
                console.error('Error when uploading', e);
              });
          }
        })
        .catch((e) => console.log('Error while deleting from Storage', e));
    } else {
      try {
        if(values.fileUrl){
          const blob = await fetch(values.fileUrl).then((r) => r.blob());
          storageRef.put(blob).then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();
            updateDocument({
              url,
              name: values.versionName,
              model_id: values.modelId,
              codeModel: values.codeModel,
              primary_interest: values.primaryInterest,
            });
          });
        }
      } catch {
        console.error('Error when uploading');
      }
      updateDocument({
        ...values,
        url: values.fileUrl,
      })
    }
  };

  const validateValues = (values) => {
    const errors = {};
    if (!values.versionName.length || values.versionName?.trim().length === 0) {
      errors.versionName = 'Veuillez renseigner le nom de la version';
    }
    if (!values.modelId.length || values.modelId?.trim().length === 0) {
      errors.modelId = 'Veuillez sélectionner le modèle';
    }
    if (!values.codeModel.length || values.codeModel?.trim().length === 0) {
      errors.codeModel = 'Veuillez renseigner le code du modèle';
    }
    if (!values.primaryInterest.length || values.primaryInterest?.trim().length === 0) {
      errors.primaryInterest = 'Veuillez renseigner le primary Interest de la version';
    }
    return errors;
  };
  if (!initialValues) return <LinearProgress variant="query" color="secondary" />;

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        versionName: initialValues.versionName,
        modelId: initialValues.modelId,
        codeModel: initialValues.codeModel,
        primaryInterest: initialValues.primaryInterest,
        fileUrl: initialValues.versionURL === undefined ? '' : initialValues.versionURL,
      }}
      validate={validateValues}
      onSubmit={(
        { versionName, modelId, codeModel, primaryInterest, fileUrl },
        { setSubmitting },
      ) =>
        handleSubmit(
          { versionName, modelId, codeModel, primaryInterest, fileUrl },
          { setSubmitting },
        )
      }
    >
      {(formik) => (
        <Container component="main">
          <VersionForm
            formik={formik}
            isLoading={isLoading}
            firebase={firebase}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            mutation="EDIT"
          />
        </Container>
      )}
    </Formik>
  );
};

const condition = (authUser) => !!authUser;

const EditModelFormContainer = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(EditModelForm);

EditModelForm.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default EditModelFormContainer;
