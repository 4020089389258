import Box from "@material-ui/core/Box";
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import assets from '../../../assets';
import { AuthUserContext } from '../../../components/Session';
import * as ROLES from '../../../constants/roles';
import * as ROUTES from '../../../constants/routes';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: 100,
    height: 50,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  menuItem: {
    borderBottom: "2px solid #D5001C",
    marginLeft: 40,
  },
  listItemText: {
    marginLeft: 40,
  },
  firstItem: {
    marginTop: 40,
  },
}));
const DrawerContainer = ({location}) => {
  const classes = useStyles();
  const authUser = useContext(AuthUserContext);
  const eventDetailPage = /event\/.*/;
  const eventUpdatePage = /event-update\/.*/;
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Link href={ROUTES.MAIN}>
        <div className={classes.toolbar}>
          <img src={assets.logo} className={classes.logo} alt="porsche logo" />
        </div>
      </Link>
      <Divider />
      <List>
        {authUser.role === ROLES.ADMIN && (
          <ListItem
            button
            component={Link} 
            href={ROUTES.AGENT}
          >
            <Box
              className={location.pathname === ROUTES.AGENT ? classes.menuItem : classes.listItemText}
              component="div"
              display="inline"
            >
              <ListItemText primary="Liste des agents" />
            </Box>
          </ListItem>
        )}
        {(authUser.role === ROLES.ADMIN || authUser.role === ROLES.AGENT) && (
          <ListItem
            button
            component={Link} 
            href={ROUTES.VENDOR}
          >
            <Box
              className={location.pathname === ROUTES.VENDOR ? classes.menuItem : classes.listItemText}
              component="div"
              display="inline"
            >
              <ListItemText primary="Liste des vendeurs" />
            </Box>
          </ListItem>
        )}
        <ListItem 
          button
          component={Link}
          href={ROUTES.EVENTS}
        >
          <Box
            className={((location.pathname === ROUTES.EVENTS) ||
              (location.pathname === ROUTES.ADD_EVENT) ||
              eventDetailPage.test(location.pathname) ||
              eventUpdatePage.test(location.pathname))
              ? classes.menuItem : classes.listItemText}
            component="div"
            display="inline"
          >
            <ListItemText primary="Évènements" />
          </Box>
        </ListItem>
        <ListItem
          button
          component={Link}
          href={ROUTES.PERSONS}
        >
          <Box
            className={location.pathname === ROUTES.PERSONS ? classes.menuItem : classes.listItemText}
            component="div"
            display="inline"
          >
            <ListItemText primary="Liste des personnes" />
          </Box>
        </ListItem>
        {authUser.role === ROLES.ADMIN && (
          <ListItem
            button
            component={Link}
            href={ROUTES.DISCHARGES}
          >
            <Box
              className={location.pathname === ROUTES.DISCHARGES ? classes.menuItem : classes.listItemText}
              component="div"
              display="inline"
            >
              <ListItemText primary="Templates décharge" />
            </Box>
          </ListItem>
        )}
        {authUser.role === ROLES.ADMIN && (
          <ListItem
            button
            component={Link}
            href={ROUTES.CATALOG}
          >
            <Box
              className={location.pathname === ROUTES.CATALOG ? classes.menuItem : classes.listItemText}
              component="div"
              display="inline"
            >
              <ListItemText primary="Catalogue" />
            </Box>
          </ListItem>)}
        {authUser.role === ROLES.ADMIN && (
          <ListItem
            button
            component={Link}
            href={ROUTES.CENTERS}
          >
            <Box
              className={location.pathname === ROUTES.CENTERS ? classes.menuItem : classes.listItemText}
              component="div"
              display="inline"
            >
              <ListItemText primary="Centres Porsche" />
            </Box>
          </ListItem>)}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          href={ROUTES.STAT}
        >
          <Box
            className={location.pathname === ROUTES.STAT ? classes.menuItem : classes.listItemText}
            component="div"
            display="inline"
          >
            <ListItemText primary="Statistiques" />
          </Box>
        </ListItem>
      </List>
    </Drawer>
  );
};
export default DrawerContainer;