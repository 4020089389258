import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Formik } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import { makeStyles } from '@material-ui/core/styles';

import { withFirebase } from '../../components/Firebase';
import IOSSwitch from '../../components/IOSSwitch';
import { AuthUserContext, withAuthorization } from '../../components/Session';
import nameRegExp from '../../utils/regexp';

const useStyles = makeStyles((theme) => ({
  FormControlLabel: {
    fontSize: 16,
    left: theme.spacing(0),
  },
  root: {
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const AddEventsFormBase = (props) => {
  const [state, setState] = useState({
    error: '', loading: false, porscheCenters: [], centersSelected: [],
  });
  const authUser = useContext(AuthUserContext);
  const [startDateIsOpen, setStartDateIsOpen] = useState(false);
  const [endDateIsOpen, setEndDateIsOpen] = useState(false);
  const classes = useStyles()
  useEffect(() => {
    setState({ ...state, loading: false });
    let unsubscribe;
    if (authUser && authUser.role === 'ADMIN') {
      unsubscribe = props.firebase
        .porscheCenters()
        .onSnapshot((snapshot) => {
          const porscheCenters = [];

          snapshot.forEach((doc) => {
            const docData = { ...doc.data() };
            porscheCenters.push(docData.porscheCenterName);
          });
          porscheCenters.push('Porsche France');
          setState({
            ...state,
            porscheCenters,
            loading: false,
          });
        });
    } else {
      unsubscribe = null;
      const porscheCenters = authUser && authUser.porscheCenters ? authUser.porscheCenters : [];
      setState({
        ...state,
        porscheCenters,
        loading: false,
      });
    }
    return function cleanup() {
      if (unsubscribe) unsubscribe();
    };
  }, []); // eslint-disable-line

  const doAddEvent = (campagneCodeEvent, nameEvent, creationDateEvent, startDateEvent, endDateEvent, porscheCentersEvent, activateSignature) => props.firebase.db.collection('events').add({
    campagneCode: campagneCodeEvent,
    name: nameEvent,
    creationDate: creationDateEvent,
    startDate: startDateEvent,
    endDate: endDateEvent,
    porscheCenters: porscheCentersEvent,
    activateSignature,
  })
    .then(() => {
      props.history.push('/events');
    })
    .catch(() => {
      // TODO: add errors handler
    });

  const handleSubmit = async ({
    campagneCode, startDate, endDate, name, setErrors, activateSignature, porscheCenters,
  }) => {

    const eventsRef = props.firebase.events();
    const eventName = await eventsRef.where('name', '==', name).get();
    const todayDate = new Date();
    const todayTimestamp = todayDate.getTime();
    if (eventName.size === 0) {
      doAddEvent(campagneCode, name, todayTimestamp, startDate, endDate, porscheCenters, activateSignature);
    } else if (eventName.size !== 0){
      setErrors({
        name: "le nom de l'événement existe déjà",
      });
    }
  };

  const validateValues = (values) => {
    const errors = {};
    if(values.campagneCode && values.campagneCode.length > 24) {
      errors.campagneCode = 'Veuillez saisir un code campagne plus petit';
    }

    if (!values.startDate) {
      errors.startDate = 'Veuillez sélectionner une date de début.';
    }
    if (!values.endDate) {
      errors.endDate = 'Veuillez sélectionner une date de fin.';
    }
    if (values.startDate && values.endDate && values.startDate > values.endDate) {
      errors.date = 'La date de fin doit être supérieure à la date de début';
    }
    if (!values.name) {
      errors.name = 'Veuillez renseigner un nom';
    } else if (values.name.length <= 2) {
      errors.name = 'Veuillez saisir un nom plus long (min. 2 caractères)';
    } else if (values.name.length > 100) {
      errors.name = 'Veuillez saisir un nom plus court (max. 100 caractères).';
    } else if (!values.name.match(nameRegExp)) {
      errors.name = 'Veuillez saisir un nom valide';
    }
    if (!values.porscheCenters.length) {
      errors.porscheCenters = 'Veuillez sélectionner un centre Porsche';
    }
    return errors;
  };
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        campagneCode: '',
        startDate: null,
        endDate: null,
        name: '',
        porscheCenters: [],
        activateSignature: false,

      }}
      validate={validateValues}
      onSubmit={({
        campagneCode, startDate, endDate, name, activateSignature, porscheCenters,
      }, { setSubmitting, setErrors }) => handleSubmit({
        campagneCode, startDate, endDate, name, setErrors, activateSignature, porscheCenters,
      }, { setSubmitting })}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        /* eslint-disable */
        handleSubmit,
        setFieldValue,
      }) => (
          <Container component="main" maxWidth="xs">
            <div>
              <br />
              <Typography component="h1" variant="h5">
                Ajouter un évènement
            </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="name"
                  label="Nom de l’évènement"
                  id="name"
                  autoComplete="name"
                  autoFocus
                  onChange={handleChange}
                  value={values.name}
                />
                {errors.name && (
                  <div style={{ color: 'red' }}>
                    {' '}
                    {errors.name }
                  </div>
                ) }
                <TextField
                  error={errors.campagneCode}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="campagneCode"
                  label="Code campagne"
                  name="campagneCode"
                  autoComplete="code campagne"
                  onChange={handleChange}
                  inputProps={{ maxLength: 24 }}
                  onBlur={(e) => {
                    handleBlur(e);
                    let codeUpper = e.currentTarget.value.toUpperCase();
                    setFieldValue('campagneCode', codeUpper);
                  }}
                  value={values.campagneCode}
                />
                {errors.campagneCode && (
                  <div style={{ color: 'red' }}>
                    {' '}
                    {errors.campagneCode }
                  </div> ) }
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                  <KeyboardDatePicker
                    variant="inline"
                    open={startDateIsOpen}
                    onOpen={() =>  setStartDateIsOpen(true)}
                    onClose={() => setStartDateIsOpen(false)}
                    id="startDate"
                    label="Date de début"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={values.startDate}
                    onChange={value => {setFieldValue('startDate', value); setStartDateIsOpen(false)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                {errors.startDate ? <div style={{ color: 'red' }}>{errors.startDate}</div> : null}
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                  <KeyboardDatePicker
                    variant="inline"
                    open={endDateIsOpen}
                    onOpen={() =>  setEndDateIsOpen(true)}
                    onClose={() => setEndDateIsOpen(false)}
                    id="endDate"
                    label="Date de fin"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={values.endDate}
                    onChange={value => {setFieldValue('endDate', value); setEndDateIsOpen(false)}}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                {errors.endDate ? <div style={{ color: 'red' }}>{errors.endDate}</div> : null}
                {errors.date && (
                  <div style={{ color: 'red' }}>
                    {' '}
                    {errors.date }
                  </div>
                ) }
                <Autocomplete
                  multiple
                  id="porscheCenters"
                  name="porscheCenters"
                  options={state.porscheCenters}
                  value={values.porscheCenters}
                  onChange={(_, value) => {
                    setFieldValue('porscheCenters', value)
                  }
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  renderOption={(center, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {center}
                    </>
                  )}
                  style={{ width: 500, padding: '20px 0px', paddingBottom: 6 }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Centre(s) Porsche" placeholder="Selectionner un centre Porsche" fullWidth />
                  )}
                />

                {errors.porscheCenters && (
                  <div style={{ color: 'red' }}>
                    {errors.porscheCenters }
                  </div>
                ) }
                <FormControlLabel
                  value="start"
                  control={
                    <IOSSwitch
                      color="primary"
                      checked={values.activateSignature}
                      onChange={(event) => setFieldValue('activateSignature', event.target.checked)}
                      name="activateSignature"
                    />
                  }
                  label={
                    <span className={classes.FormControlLabel} >
                      {"Essais (signature d'une décharge de responsabilité) :"}
                    </span>
                  }
                  className={classes.root}
                  labelPlacement="start"
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Ajouter
              </Button>
              </form>
            </div>
          </Container>
        )}
    </Formik>
  );
};

const condition = authUser => !!authUser;

const AddEventsForm = compose(
  withRouter,
  withFirebase,
  withAuthorization(condition),
)(AddEventsFormBase);

AddEventsFormBase.propTypes = {
  firebase: PropTypes.shape({
    db: PropTypes.object,
    events: PropTypes.func,
    porscheCenters: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddEventsForm;
