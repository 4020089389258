/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, {useContext, useEffect, useState, useRef, useMemo} from 'react';
import {DialogActions, DialogContentText, DialogContent, DialogTitle,useMediaQuery,Button, Fab,IconButton,TextField, Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Formik } from "formik";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {formatDate} from './helper';
import { FirebaseContext } from '../../components/Firebase';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 'normal',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    boxSizing: "initial",
  },
  margin: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  Fab: {
    marginLeft: '5px',
    marginRight: '5px',
    maxWidth: "35px",
    maxHeight: "30px",
  },
}));

const PDF_MODAL ='PDF_MODAL';
const DELETE_ERROR_MODAL = 'DELETE_ERROR_MODAL';
const UPDATE_NAME_MODAL = 'UPDATE_NAME_MODAL';

const initialState = {
  typeModal: null,
  idTemplate: null,
  urlTemplate: null,
  nameTemplate: "",
}

const initialStateAlert = {
  typeAlert: null,
  message: '',
}

const DischargesPage = () => {

  const {storage, db, timestamp} = useContext(FirebaseContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("false"));

  const [templates, setTemplates] = useState([]);
  const [users, setUsers] = useState([]);

  const [dataModal, setDataModal] = useState(initialState);

  const [dataAlert, setDataAlert] = useState(initialStateAlert);

  const refFileInput = useRef(null);

  const classes = useStyles();

  useEffect(()=> {
    const subscribe = db.collection("templates")
      .onSnapshot((snapshot) => {
        if(!snapshot.empty){
          const data = snapshot.docs.map((elem) => ({
            id: elem.id,
            ...elem.data(),
          }));
          setTemplates(data);
        }
      }, (error) => {
        console.log('error template subscriber:', error);
      });

    return () => {
      subscribe();
    };
  }, [db])

  useEffect(()=> {
    const subscribe = db.collection("users").where("role", 'in', ['AGENT', 'VENDEUR'])
      .onSnapshot((snapshot) => {
        if(!snapshot.empty){
          const data = snapshot.docs.map((elem) => ({
            id: elem.id,
            ...elem.data(),
          }));
          setUsers(data);
        }
      }, (error) => {
        console.log('error subscriber users:', error);
      });

    return () => {
      subscribe();
    };
  }, [db])

  const handleClose = () => setDataModal(initialState);
  const handleOpen = (typeModal, option = initialState) => {
    const newState = {
      ...initialState,
      typeModal,
      idTemplate: option.idTemplate ? option.idTemplate : null,
      urlTemplate: option.urlTemplate ? option.urlTemplate : null,
      nameTemplate: option.nameTemplate ? option.nameTemplate : "",
    }
    setDataModal(newState);
  }

  const handleCloseAlert = () => setDataAlert(initialStateAlert);
  const handleOpenAlert = (type, message) => {
    setDataAlert(
      {
        type,
        message,
      }
    )
  }

  const counter = useMemo(() => templates.reduce((acc, {id}) => {
    let nbrVendors = 0;
    let nbrAgents = 0;
    users.forEach(({idTemplate, role, idTemplateEvent}) => {
      if(role === 'AGENT' && (id === idTemplate || id === idTemplateEvent)){
        nbrAgents += 1;
      }
      if(role === 'VENDEUR' && (id === idTemplate || id === idTemplateEvent)){
        nbrVendors += 1;
      }
    })

    acc[id] = {
      nbrVendors,
      nbrAgents,
    }

    return acc;
  }, 
  {})
  , [templates, users]) 


  const handleAdd = async(event) => {
    try {
      event.persist();
      const file = event.target.files[0];

      const existInFirestore = templates.some((elem)=> elem.currentName === file.name.replace('.pdf', ''));
      if(existInFirestore){
        handleOpenAlert('error','Un fichier existe qui porte le même nom !');
        return ;
      }

      const existInStorage = templates.some((elem)=> elem.originalName === file.name);
      const originalName = existInStorage ? `${file.name.replace('.pdf','')}_${Date.now()}.pdf` : file.name;

      const refPdf = storage.ref().child(`templates/${originalName}`);
      const uploadTask = await refPdf.put(file);

      // after upload pdf to store , we have to create an new doc in collection templates
      if(uploadTask.state === "success"){
        const url = await uploadTask.ref.getDownloadURL();
        const newTemplate = {
          originalName: uploadTask.metadata.name,
          currentName: file.name.replace('.pdf',''),
          dateFormatted: formatDate(timestamp.fromDate(new Date())),
          link: url,
          fullPath: uploadTask.metadata.fullPath,
        }
        await db.collection('templates').add(newTemplate);
        handleOpenAlert('success','Ajout template avec success');
      }
    } catch (error) {
      console.log(`error add file with name ${event.target.files[0].name}:`, error);
      handleOpenAlert('error',"Echec d'ajout du template !");
    }
    finally{
      refFileInput.current.value = "";
    }
  }

  const handleDelete = async(idTemplate,fullPath) => {
    try {
      const attachedUsers = users.some((user) => user.idTemplate === idTemplate || user.idTemplateEvent === idTemplate);

      if(attachedUsers) {
        handleOpen(DELETE_ERROR_MODAL);
        return ;
      }
      await storage.ref().child(fullPath).delete();
      await db.collection('templates').doc(idTemplate).delete();
      handleOpenAlert('success','Suppression template avec success');
    } catch (error) {
      console.log(`error delete file with id ${idTemplate}:`, error);
      handleOpenAlert('error','Echec suppression template');
    }
  }

  const handleUpdate = async(idTemplate, newName) => {
    try {
      const refDoc = db.collection('templates').doc(idTemplate);
      await refDoc.update({currentName: newName.replace('.pdf', '')});
      handleOpenAlert('success','Modification nom template avec success');
    } catch (error) {
      console.log(`Error update file with id:${idTemplate}`, error);
      handleOpenAlert('error','modification nom template echoué');
    }
  }

  const validateValues = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Ce champ est obligatoire";
    }else if(values.name.length > 50){
      errors.name = "Doit contenir 50 caractères ou moins";
    }
    else {
      const check = templates.some((temp) => temp.currentName === values.name);
      if( check) {
        errors.name = "ce nom est deja utilisé !";
      }
    }
    
    return errors;
  };


  return (
    <div style={{padding: "18px"}}>
      <div>
        <h1>
          Templates décharges de responsabilité 
        </h1>
      </div>

      <div style={{textAlign: 'right', margin:'10px', marginBottom: "30px"}}>
        <label
          htmlFor='upload_template'
          style={{
            backgroundColor: '#d5001c',
            cursor: 'pointer',
            padding: '0.5rem',
            color: 'white',
            fontSize: '0.95rem',
            boxSizing: 'border-box',
            fontFamily: 'PorscheNextTT, sans-serif',
            fontWeight: 700,
            borderRadius: '4px',
            boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          }}
        >
            Nouveau template
        </label>
        <input
          id='upload_template' type='file' accept='.pdf' ref={refFileInput} hidden
          onChange={handleAdd}
        />
      </div>

      <Snackbar open={Boolean(dataAlert.type)} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={dataAlert.type}>
          {dataAlert.message}
        </Alert>
      </Snackbar>

      <TableContainer component={Paper}>
        <Table  aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nom du template</StyledTableCell>
              <StyledTableCell align="left">Date de création</StyledTableCell>
              <StyledTableCell align="left">Agent(s) rattaché(s)</StyledTableCell>
              <StyledTableCell align="left">Vendeur(s) rattaché(s)</StyledTableCell>
              <StyledTableCell align="left">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map(({id, currentName, dateFormatted, fullPath, link}) => (
              <TableRow key={id}>
                <StyledTableCell component="th" scope="row">
                  {currentName}
                </StyledTableCell>
                <StyledTableCell align="left">{dateFormatted}</StyledTableCell>
                <StyledTableCell align="left" >
                  {counter[id].nbrAgents}
                </StyledTableCell>
                <StyledTableCell align="left" >
                  {counter[id].nbrVendors}
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    style={{
                      align: "left",
                      width: "100%",
                      minWidth: '90px',
                    }}
                  >
                    <Fab
                      className={classes.Fab}
                      onClick={() => handleOpen(PDF_MODAL,{urlTemplate: link})}
                      color="primary">
                      <EyeIcon />
                    </Fab>
                    <Fab
                      className={classes.Fab}
                      color="primary"
                      aria-label="view"
                      onClick={()=> handleDelete(id, fullPath)}
                    >
                      <DeleteIcon />
                    </Fab>
                    <Fab
                      className={classes.Fab}
                      color="default"
                      onClick={() =>{handleOpen(UPDATE_NAME_MODAL,{idTemplate: id, nameTemplate: currentName})}}
                    > 
                      <EditIcon />
                    </Fab>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={dataModal.typeModal === DELETE_ERROR_MODAL}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "10px 24px" }}>
          <DialogTitle>
            <span style={{ textTransform: "none" }}>
            Suppression du template de décharge de responsabilité 
            </span>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText color="primary">
              Des agents et/ou des vendeurs sont rattachés à ce template. Il ne peut être supprimé.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              paddingBottom: "20px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={handleClose}
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Dialog 
        open={dataModal.typeModal === PDF_MODAL}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent style={{padding: '0px'}}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            style={{
              position:"absolute",
              top:55,
              right:15,
              
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <iframe
            title="pdf"
            src={`${dataModal.urlTemplate}`} 
            style={{width: '600px', height:'600px', padding: '0px'}} frameBorder="0"></iframe>
        </DialogContent>
      </Dialog>
      
      <Dialog
        open={dataModal.typeModal === UPDATE_NAME_MODAL}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={{
            name: dataModal.nameTemplate,
          }} 
          validate={validateValues}
          onSubmit={(values)=> {
            handleUpdate(dataModal.idTemplate, values.name);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
          }) => (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(values);
              }}
            >
              <DialogTitle style={{ textAlign: 'center' }}>
                <span style={{ textTransform: "none" }}>
                  Modifier le nom du template
                </span>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Saisissez ici le nouveau nom du template
                </DialogContentText>
                <TextField
                  type="ematextil"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  name="name"
                  autoComplete="name"
                  autoFocus
                  onChange={handleChange}
                  value={values.name}
                  label="nom du template"
                  style={{ marginBottom: "5px", width: "500px" }}
                />
                {errors.name && (
                  <div style={{ color: "#d5001c", padding: "4px 18px" }}>
                    {errors.name}
                  </div>
                )}
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Annuler
                </Button>
                <Button
                  type="submit"
                  className={classes.submit}
                  variant="contained"
                  color="secondary"
                >
                  <span style={{ paddingRight: "10px" }}>
                    &gt;
                  </span>
                  Enregistrer
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </div>)
};

export default DischargesPage;
