import { useCallback, useEffect, useState } from 'react';

export default function useGuestStatInfo(event, firebase) {
  const [state, setState] = useState({});
  const unsubscribe = useCallback(async () => {
    if (event) {
      await firebase
        .event(event.eventID)
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          if (!snapshot.empty) {
            setState({
              totalGuests: data.totalGuests || 0,
              totalPresentGuests: data.totalPresentGuests || 0,
              totalAbsentGuests: data.totalAbsentGuests || 0,
              totalConfirmedGuest: data.totalConfirmedGuest || 0,
              totalNotPresentConfirmedGuest: data.totalNotPresentConfirmedGuest || 0,
              totalPresentConfirmedGuest: data.totalPresentConfirmedGuest || 0,
              totalProspect: data.totalProspect || 0,
              totalLead: data.totalLead || 0,
            });
          } else {
            setState({
              totalGuests: 0,
              totalPresentGuests: 0,
              totalAbsentGuests: 0,
              totalConfirmedGuest: 0,
              totalNotPresentConfirmedGuest: 0,
              totalPresentConfirmedGuest: 0,
              totalProspect: 0,
              totalLead: 0,
            });
          }
        })
    }
  }, [event]); // eslint-disable-line
  useEffect(() => {
    unsubscribe();
    return () => {
      unsubscribe()
    }
  }, [event]); // eslint-disable-line

  return { guestsData: state };
}
