import deleteLogo from './images/delete_logo.png';
import PorscheLogo from './images/Logo_porsche.png';
import RedArrow from './images/Red_arrow.png'
import template from './templates/templateGuest.xlsx';
import IMG_PLACEHOLDER from './images/Re-Placeholder.png';
import dragDropIcon from './images/drag_drop_icon.png';

export default {
  logo: PorscheLogo,
  delete: deleteLogo,
  redArrow: RedArrow,
  templateGuest: template,
  IMG_PLACEHOLDER,
  dragDropIcon,
};
