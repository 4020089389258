import React, { useRef, useState, useEffect } from 'react';
import { Form } from 'formik';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import assets from '../../assets';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '10%',
    marginBottom: '10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  form: {
    width: '100%',
    maxWidth: '950px',
  },
  inputFileContainer: {
    marginTop: '25px',
    marginRight: '20px',
  },
  input: {
    width: '409px',
  },
  imgContainer: {
    height: '330px',
    maxWidth: '535px',
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
  },
  fileControl: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'flex-start',
    maxWidth: '388px',
    marginTop: '10px',
  },
  button: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    textTransform: 'none',
    marginRight: '30px',
  },
  row: {
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  submitButton: {
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '23px',
    float: 'right',
  },
  inputTextContainer: {
    paddingTop: '10px',
  },
  titleContainer: {
    minHeight: '32px',
    position: 'relative',
    marginBottom: '18px',
    '& > h2': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
}));

const { IMG_PLACEHOLDER } = assets;

const FILE_PROPS = {
  width: 328,
  height: 246,
  errors: {
    format:
      'Format erroné, veuillez respecter la taille d’image suivante : 328px width 246px height',
    required: 'Veuillez charger une image',
  },
};

const GammeForm = ({ formik, isLoading, firebase, handleSubmit, mutation, initialValues }) => {
  const [fileError, setFileError] = useState({
    isValid: false,
    msg: '',
  });

  useEffect(() => {
    if (mutation === 'EDIT') {
      setFileError({
        isValid: true,
        msg: '',
      });
    }
  }, [mutation]);

  const classes = useStyles();
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const { type } = file;
    if (!type.startsWith('image/')) {
      setFileError({
        isValid: false,
        msg: FILE_PROPS.errors.required,
      });
      return;
    }
    try {
      const fileUploadedUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = fileUploadedUrl;
      img.onload = () => {
        if (img.height !== FILE_PROPS.height || img.width !== FILE_PROPS.width) {
          setFileError({
            isValid: false,
            msg: FILE_PROPS.errors.format,
          });
          return;
        }
        formik.setFieldValue('fileUrl', fileUploadedUrl);
        setFileError({
          isValid: true,
          msg: '',
        });
      };
    } catch (e) {
      console.error('Error uploading', e);
    }
  };

  const resetFileInput = () => {
    formik.setFieldValue('fileUrl', IMG_PLACEHOLDER);
    setFileError({
      isValid: false,
      msg: FILE_PROPS.errors.required,
    });
  };

  const handleAdd = async () => {
    try {
      const docRef = await firebase.db
        .collection('porsche-gammes')
        .get();
      if (initialValues?.gammeName !== formik.values.gammeName &&
          docRef.docs.length > 0 &&
          (docRef.docs.findIndex((gamme) => gamme.data().name.toLowerCase() === formik.values.gammeName.toLowerCase()) !== -1)
      ) {
        formik.setFieldError('gammeName', 'Cette gamme existe déjà');
      } else {
        handleSubmit(formik.values);
      }
    } catch (error) {
      console.error('Error getting document:', error);
    }
  };

  const isDisabled =
    isLoading ||
    !formik.values.gammeName ||
    !fileError.isValid ||
    formik.values.fileUrl === ''

  return (
    <Form className={classes.form}>
      <h1>Catalogue</h1>
      <div className={classes.container}>
        <div>
          <div className={classes.titleContainer}>
            <h2>{mutation === 'ADD' ? 'Nom de la gamme' : formik.values.gammeName}</h2>
          </div>
          <div className={classes.row}>
            <div className={classes.inputFileContainer}>
              <div className={classes.imgContainer}>
                <img
                  src={formik.values.fileUrl ? formik.values.fileUrl : assets.IMG_PLACEHOLDER}
                  className={classes.img}
                  alt=""
                />
              </div>
              <div>
                {!fileError.isValid && fileError.msg && (
                  <FormHelperText error>{fileError.msg}</FormHelperText>
                )}
              </div>
              <div className={classes.fileControl}>
                <div>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleClick}
                  >
                    Charger un visuel
                  </Button>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    key={formik.values.fileUrl}
                  />
                </div>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    backgroundColor: '#959899',
                    display: formik.values.fileUrl === '' ? 'none' : 'block',
                  }}
                  onClick={resetFileInput}
                >
                  Supprimer le visuel
                </Button>
              </div>
            </div>
            <div className={classes.inputTextContainer}>
              <TextField
                name="gammeName"
                error={formik.errors.gammeName && formik.touched.gammeName}
                variant="outlined"
                type="text"
                margin="normal"
                fullWidth
                helperText={
                  formik.errors.gammeName && formik.touched.gammeName ? formik.errors.gammeName : ''
                }
                label="Nom de la gamme"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.gammeName}
                className={classes.input}
              />
            </div>
          </div>
        </div>
      </div>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        className={classes.submitButton}
        disabled={isDisabled}
        onClick={handleAdd}
      >
        Enregistrer
      </Button>
    </Form>
  );
};

export default GammeForm;
